import React, { Fragment, useMemo } from 'react';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { GridList, GridListTile } from '@material-ui/core';
import ImageButton from '../../../Components/Buttons/ImageButton';
import { CONTRACT_TYPES } from '../../Contracts/contract_enums';

// * ICONS

import PaymentsIcon from '../../../Components/CustomIcons/PaymentsIcon';
import DuplicatesIconButton from '../../../Components/CustomIcons/IcDuplicatesButton';
import BrillaIcon from '../../../Components/CustomIcons/BrillaIcon';
import SubrogationRequestIcon from '../../../Components/CustomIcons/SubrogationRequestIcon';
import IndustrialOptions from './IndustrialOptions';
import StandardOptions from './StandardOptions';

// Keep in mind that, for the time being, this component is meant for GDC only.
const HomeOptions = ({
  classes,
  contracts,
  selectedContract,
  history,
  canPayPartially,
  goToPartialPayment,
  onOpenDuplicates,
  isSmallSize,
  isMediumSize
}) => {
  const deviceColumns = useMemo(() => {
    if (isSmallSize) {
      return 2;
    }
    if (isMediumSize) {
      return 3;
    }
    return 4;
  }, [isSmallSize, isMediumSize]);

  const isIndustrial = useMemo(() => {
    return (
      selectedContract &&
      (selectedContract.type === CONTRACT_TYPES.Industrial ||
        selectedContract.type === CONTRACT_TYPES.Unregulated)
    );
  }, [selectedContract]);

  if (contracts.length === 0) {
    return (
      <GridList cellHeight="auto" spacing={16} cols={deviceColumns}>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_payments"
            icon={PaymentsIcon}
            title={'Pagos'}
            onClick={() =>
              history.push(ROUTE_NAMES.payments, {
                type: 'contract',
                value: ''
              })
            }
          />
        </GridListTile>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_brilla"
            icon={BrillaIcon}
            title={'Mi cupo Brilla'}
            aria-label="Mi cupo Brilla"
            onClick={() => history.push(ROUTE_NAMES.brilla)}
          />
        </GridListTile>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_duplicates"
            icon={DuplicatesIconButton}
            title={'Generar cupón'}
            aria-label="genera_un_cupon_de_pago"
            onClick={onOpenDuplicates}
          />
        </GridListTile>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_subrogation_request"
            icon={SubrogationRequestIcon}
            title="Solicitud de subrogación"
            aria-label="Solicitar subrogación"
            onClick={() => history.push(ROUTE_NAMES.subrogationRequest)}
          />
        </GridListTile>
      </GridList>
    );
  }

  return (
    <Fragment>
      {isIndustrial && (
        <IndustrialOptions
          classes={classes}
          history={history}
          selectedContract={selectedContract}
          deviceColumns={deviceColumns}
          onOpenDuplicates={onOpenDuplicates}
        />
      )}
      {!isIndustrial && (
        <StandardOptions
          classes={classes}
          selectedContract={selectedContract}
          history={history}
          canPayPartially={canPayPartially}
          goToPartialPayment={goToPartialPayment}
          onOpenDuplicates={onOpenDuplicates}
          deviceColumns={deviceColumns}
        />
      )}
    </Fragment>
  );
};

export default HomeOptions;
