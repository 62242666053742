/* eslint-disable complexity */
import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect
} from 'react';
import _get from 'lodash/get';
import {
  Container,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import Stepper from '../../../Components/Stepper/Stepper';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../Components/Buttons/BaseButton';
import { defaultAlertError } from '../../../Components/Alerts/AlertMessages';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';

import { extractErrorMessage } from '../../../Utils/Errors/Errors';

import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import SecondStep from '../SecondStep/Second';
import PaymentOptions from '../FirstStep/PaymentOptions';
import {
  INDIVIDUAL_STATE,
  INDIVIDUAL_LOAD_INITIAL_STATE,
  MULTIPLE_LOAD_INITIAL_STATE,
  TABS_INITIAL_STATE,
  TRANSACTION_STATUS,
  TYPE_OF_PAYMENTS,
  TRANSACTION_TYPES,
  PAYMENT_VALUE_OPTION,
  PAYMENT_STEPS,
  FORM_ID
} from '../payment_enums';
import { getTotalAmount } from '../helpers/helpers';

// * API
import {
  PayPseAPI,
  CreateGroupedPaymentAPI
} from '../../../API/Payments/PaymentsAPI';
import {
  CreateFinancingTransactionAPI,
  GetPartialPaymentCouponAPI
} from '../../../API/Debts/DebtsAPI';

import {
  DefaultParams,
  buildFinancingPSERequest,
  buildPSERequest
} from './Payment';
import { Company } from '../../../Configs/general';
import { strSearchParams2Object } from '../../TransactionsList/helpers/helpers';
import {
  InsurancePlan,
  InsuranceProduct,
  InsuranceProductType
} from '../../../Enums/insurances';
import {
  GetInsurancePlanPricesAPI,
  RequestInsuranceAPI,
  VerifyContractEligibilityAPI
} from '../../../API/BrillaInsurances/BrillaInsurancesAPI';
import InsuranceDialog from '../../../Components/Dialogs/CallToActionDialog';
import moment from 'moment';
import {
  GenerateDuplicateCouponAPI,
  GetCouponPDFAPI
} from '../../../API/Coupons/CouponsAPI';
import { saveAsPDF } from '../../../Utils/Transform/Files';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import { InvoicePeriod } from '../../../Enums/invoices';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import silverPlanImgDesktop from '../../../Assets/images/BANNER_PLAN_SILVERS_386x245_desktop.jpg';
import silverPlanImgMobile from '../../../Assets/images/BANNER_PLAN_SILVERS_328x230_mobile.jpg';
import { Step, StepperProvider } from '../../../Contexts/StepperContext';
import FooterStepper from '../../../Components/Stepper/FooterStepper';
import clsx from 'clsx';

const PaymentGDC = props => {
  const { match, location = { state: null } } = props;

  const params = location.state ? location.state : DefaultParams;
  const parsedParams = strSearchParams2Object(window.location.search);

  const backToDeferredPayment = useCallback(() => {
    history.replace(ROUTE_NAMES.deferredPayment, {
      initialStepIndex: 2,
      couponId: params.value,
      addedDeferreds: params.addedDeferreds,
      selectedContract: params.selectedContract
    });
  }, [params.value, params.addedDeferreds, params.selectedContract]);

  if (match && match.params) {
    if (match.params.contractId) {
      params.value = match.params.contractId;
      params.type = TYPE_OF_PAYMENTS.contract;
    } else if (match.params.couponId) {
      params.value = match.params.couponId;
      params.type = TYPE_OF_PAYMENTS.coupon;
    }
  }

  const paramsPayments = (params || {}).payment;
  const paramsPayment = (paramsPayments || [])[0] || {};
  const defaultPaymentValueOption = paramsPayment.canBePaidPartially
    ? ''
    : PAYMENT_VALUE_OPTION.TotalCoupon;

  // * STATE HOOKS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const authToken = _get(currentUser, 'token', null);
  const isIndividualTabParam =
    _get(parsedParams, 'individualTab', false) === 'true';
  const isMultiplePaymentsParam =
    _get(parsedParams, 'multiplePayments', false) === 'true';

  const [currentStep, setCurrentStep] = useState(params.currentStep || 0);
  const [goBack, setGoBack] = useState({});
  const [nextButtonText, setNextButtonText] = useState('Siguiente');
  const [number, setNumber] = useState(params.value);
  const [type, setType] = useState(params.type);
  const [loading, setLoading] = useState(false);
  const [disabledNext, setDisabledNext] = useState(
    params.disabledNext !== false
  );
  const [payments, setPayments] = useState(params.payment || []);
  const [methodSelected, setMethodSelected] = useState('');
  const [isCardStep, setCardStep] = useState(false);
  const [payerInfo, setPayerInfo] = useState(null);
  const [insuranceInfo, setInsuranceInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [cardIndividualLoad, setCardIndividualLoad] = useState({
    ...INDIVIDUAL_LOAD_INITIAL_STATE,
    toPay: params.payment || []
  });
  const [cardMultipleLoad, setCardMultipleLoad] = useState(
    MULTIPLE_LOAD_INITIAL_STATE
  );
  const [generatedGroupedCoupon, setGeneratedGroupedCoupon] = useState(null);
  const [paymentsCopy, setPaymentsCopy] = useState([]);
  const [totalCurrentCardsCopy, setTotalCurrentCardsCopy] = useState('');
  const [checkedMultiplePayments, setCheckedMultiplePayments] = useState(
    isMultiplePaymentsParam
  );
  const [currentTabIndex, setCurrentTabIndex] = useState(
    isIndividualTabParam ? INDIVIDUAL_STATE : TABS_INITIAL_STATE
  );
  const [showPlanInfo, setShowPlanInfo] = useState(false);
  const [silverPlanPrice, setSilverPlanPrice] = useState(null);
  const [isValidForInsurance, setIsValidForInsurance] = useState(null);
  const [showInsuranceDialog, setShowInsuranceDialog] = useState(false);
  const [paymentValueOption, setPaymentValueOption] = useState(
    defaultPaymentValueOption
  );
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [updateStepIndex, setUpdateStepIndex] = useState(false);

  const paymentData = useMemo(() => payments.length && payments[0], [payments]);
  const canPayPartially = paymentData.canBePaidPartially;

  const isFinancingPayment = paymentData.type === TYPE_OF_PAYMENTS.financing;
  const withGroupedCoupon = (paymentData.detail || {}).couponId < 0;

  const isDisableBackCase = isFinancingPayment || withGroupedCoupon;
  const disabledBackButton = currentStep === 1 && isDisableBackCase;

  // * OTHER HOOKS
  const classes = useStyles();

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  useEffect(() => {
    let ignoreRequest = false;
    const fetchData = async () => {
      if (ignoreRequest) {
        return;
      }
      const response = await GetInsurancePlanPricesAPI(authToken);

      if (response.success) {
        const responseData = response.data;

        if (!responseData || !responseData.valid) {
          return;
        }

        const planData = responseData.prices.find(
          ({ id }) => id === InsurancePlan.Silver
        );

        setSilverPlanPrice(planData.price);
      }
    };

    if (!silverPlanPrice && currentUser && !checkedMultiplePayments) {
      fetchData();
    }

    return () => {
      ignoreRequest = true;
    };
  }, [authToken, currentUser, silverPlanPrice, checkedMultiplePayments]);

  useEffect(() => {
    const paymentDetails = payments.length && payments[0].detail;
    const contractId = paymentDetails && paymentDetails.contractId;

    if (checkedMultiplePayments) {
      setIsValidForInsurance(false);
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      if (ignoreRequest) {
        return;
      }
      const response = await VerifyContractEligibilityAPI(
        currentUser.token,
        contractId,
        InsuranceProductType.Life,
        18100
      );
      if (response.success) {
        setIsValidForInsurance(response.valid);
      }
    };

    if (
      isValidForInsurance === null &&
      silverPlanPrice &&
      currentUser &&
      contractId &&
      !checkedMultiplePayments
    ) {
      fetchData();
    }

    return () => {
      ignoreRequest = true;
    };
  }, [
    currentUser,
    silverPlanPrice,
    payments,
    checkedMultiplePayments,
    isValidForInsurance
  ]);

  // * FUNCTIONS

  const handleGoBack = useCallback(() => {
    setPayerInfo(null);
    setShowPlanInfo(false);
    if (payments.length && payments.type === TYPE_OF_PAYMENTS.couponless) {
      history.goBack();
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep, payments]);

  const handleGoToCardStep = useCallback(() => {
    setCardStep(true);
  }, [setCardStep]);

  const totalIndividualCards = useMemo(() => {
    return [
      ...cardIndividualLoad.error,
      ...cardIndividualLoad.pending,
      ...cardIndividualLoad.paid,
      ...cardIndividualLoad.toPay,
      ...cardIndividualLoad.loading
    ];
  }, [cardIndividualLoad]);

  const totalMultipleLoadCards = useMemo(() => {
    return [
      ...cardMultipleLoad.error,
      ...cardMultipleLoad.pending,
      ...cardMultipleLoad.paid,
      ...cardMultipleLoad.toPay,
      ...cardMultipleLoad.loading
    ];
  }, [cardMultipleLoad]);

  const totalCurrentCards = useMemo(() => {
    return [...totalIndividualCards, ...totalMultipleLoadCards];
  }, [totalIndividualCards, totalMultipleLoadCards]);

  const handleSaveCopies = useCallback(() => {
    setPaymentsCopy(JSON.stringify(payments));
    setTotalCurrentCardsCopy(JSON.stringify(totalCurrentCards));
  }, [payments, totalCurrentCards]);

  const handleGoForward = useCallback(() => {
    handleSaveCopies();
    setCurrentStep(currentStep + 1);
    setUpdateStepIndex(true);
  }, [currentStep, setCurrentStep, handleSaveCopies]);

  const handleGroupedCouponPayment = useCallback(async () => {
    if (!(payments.length > 0)) {
      return;
    }

    const totalPayment = getTotalAmount(payments);
    try {
      const { success, data } = await CreateGroupedPaymentAPI(
        payments,
        totalPayment
      );
      if (success) {
        setGeneratedGroupedCoupon(data.data.couponId);
        setGeneratedGroupedCoupon({
          type: 'coupon',
          couponId: data.data.couponId,
          detail: {
            expirationDate: '-',
            generationDate: '-',
            contractAddress: null,
            contractCity: null,
            isPaid: false,
            isPending: false,
            couponId: data.data.couponId,
            contractId: '-',
            value: totalPayment
          }
        });
        handleGoForward();
      }
    } catch (err) {
      setAlert({
        type: ALERT_TYPE.WARNING,
        message: `Ha ocurrido un error, por favor revise ${Company.contractConjugation.regular.plural.article} y cupones ingresados`,
        onClose: () => history.push(`${ROUTE_NAMES.payments}`)
      });
      return;
    }
  }, [handleGoForward, payments, setAlert]);

  const insuranceRequest = useCallback(
    async couponId => {
      if (currentUser && showPlanInfo) {
        const { gender, dateOfBirth, expeditionDate } = insuranceInfo;
        const insureds = [
          {
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            identificationType: payerInfo.typeOfIdentification,
            identification: payerInfo.identification.toString(),
            dateOfBirth: moment(dateOfBirth).toISOString(),
            expeditionDate: moment(expeditionDate).toISOString(),
            phone: payerInfo.phone.toString(),
            phoneCountryCode: payerInfo.phoneCountryCode,
            gender,
            email: payerInfo.email
          }
        ];

        const insuranceData = {
          insureds,
          plan: InsurancePlan.Silver,
          product: InsuranceProduct.Practiseguro,
          value: insuranceInfo.price,
          beneficiaries: [],
          contractId: payments[0].detail.contractId,
          couponId
        };

        const response = await RequestInsuranceAPI(
          currentUser.token,
          insuranceData
        );

        if (response.data && response.data.status === 'success') {
          const insuranceRequestId = response.data.data.requestId;
          return {
            insuranceData: { ...insuranceData, insuranceRequestId }
          };
        }

        return null;
      }
    },
    [currentUser, showPlanInfo, payerInfo, payments, insuranceInfo]
  );

  const getPartialCoupon = useCallback(
    async payment => {
      const { partialData, detail } = payment;
      const {
        totalCurrentDebtValue,
        partialPayment,
        productsDetails
      } = partialData;

      const body = {
        totalCurrentDebtValue,
        partialPayment,
        productsDetails,
        referenceCouponId: detail.couponId
      };

      let recaptchaToken;
      if (!authToken) {
        recaptchaToken = await getRecaptchaToken(
          RecaptchaAction.PartialCouponPayments
        );
      }

      const { success, data, error } = await GetPartialPaymentCouponAPI(
        detail.contractId,
        body,
        authToken,
        recaptchaToken
      );

      if (success) {
        const { couponId } = data.data;
        return couponId;
      }
      defaultAlertError(error, setAlert);
    },
    [authToken, setAlert]
  );

  const getDuplicateCoupon = useCallback(
    async (contractId, period, origin) => {
      const { success, error, data } = await GenerateDuplicateCouponAPI(
        contractId,
        period,
        authToken,
        origin
      );

      if (success) {
        const { couponId } = data.data;
        if (paymentValueOption === PAYMENT_VALUE_OPTION.CurrentDebt) {
          setPayments([
            {
              ...paymentData,
              partialData: { ...paymentData.partialData, couponId }
            }
          ]);
        }
        return couponId;
      }

      defaultAlertError(error, setAlert);
    },
    [authToken, setAlert, paymentValueOption, paymentData]
  );

  const getCouponIdHandler = useCallback(
    async evt => {
      const { partialData, detail } = paymentData;
      const { couponId: generatedCouponId } = partialData || {};

      switch (paymentValueOption) {
        case PAYMENT_VALUE_OPTION.TotalCoupon:
          return detail.couponId;
        case PAYMENT_VALUE_OPTION.CurrentDebt: {
          // This happens when the user download the coupon payment
          // coupon first and then try to do the payment
          if (generatedCouponId) {
            return generatedCouponId;
          }

          const eventTarget = evt && evt.target;
          const componentId = (eventTarget && eventTarget.id) || '';
          const componentIdFromParent =
            (eventTarget &&
              eventTarget.offsetParent &&
              eventTarget.offsetParent.id) ||
            '';

          return await getDuplicateCoupon(
            detail.contractId,
            InvoicePeriod.Current,
            {
              componentId: componentId || componentIdFromParent,
              url: window.location.pathname
            }
          );
        }
        case PAYMENT_VALUE_OPTION.Partial: {
          // This happens when the user download the partial payment
          // coupon first and then try to do the payment
          if (generatedCouponId) {
            return generatedCouponId;
          }

          return await getPartialCoupon(paymentData);
        }

        default:
          return;
      }
    },
    [paymentValueOption, getPartialCoupon, paymentData, getDuplicateCoupon]
  );

  const handlePsePayment = useCallback(
    async couponId => {
      if (!(payments.length > 0) || !couponId) {
        return;
      }

      // pre-request states
      setLoading(true);
      setDisabledNext(true);

      if (
        isValidForInsurance &&
        !showInsuranceDialog &&
        !showPlanInfo &&
        paymentValueOption !== PAYMENT_VALUE_OPTION.Partial &&
        !paymentData.isPartialCoupon
      ) {
        setShowInsuranceDialog(true);
        setLoading(false);
        setDisabledNext(false);
        return;
      }

      setShowInsuranceDialog(false);

      // request Insurances API

      const insuranceResponse = await insuranceRequest(couponId);

      const insuranceData = insuranceResponse
        ? insuranceResponse.insuranceData
        : null;

      // request to api
      const body = buildPSERequest(couponId, payerInfo);
      const { success, data, error } = await PayPseAPI(
        {
          ...body,
          product: {
            couponId,
            donationValue: 0
          },
          insuranceData: insuranceData
            ? { policyData: { ...insuranceData } }
            : null,
          transactionType:
            paymentValueOption === PAYMENT_VALUE_OPTION.Partial
              ? TRANSACTION_TYPES.PartialPayment
              : null
        },
        _get(currentUser, 'token', null)
      );

      // update last bank only if there is logged user
      if (currentUser) {
        setCurrentUser(user => ({
          ...user,
          lastSelectedBank: payerInfo.currentBank
        }));
      }

      if (success) {
        const transaction = data.data;

        if (
          transaction.status === TRANSACTION_STATUS.Pending &&
          transaction.bankUrl
        ) {
          window.location.href = transaction.bankUrl;
          return;
        }

        if (data.status === 'warning') {
          setAlert({
            type: ALERT_TYPE.WARNING,
            message: data.message,
            onClose: () =>
              history.push(
                `${ROUTE_NAMES.transaction}/${transaction.transactionId}`
              )
          });

          return;
        }

        history.replace(
          `${ROUTE_NAMES.transaction}/${transaction.transactionId}`
        );

        return;
      }
      defaultAlertError(error, setAlert);
      setDisabledNext(false);
      setLoading(false);
    },
    [
      payments,
      setLoading,
      setDisabledNext,
      payerInfo,
      currentUser,
      setAlert,
      setCurrentUser,
      insuranceRequest,
      isValidForInsurance,
      showInsuranceDialog,
      showPlanInfo,
      paymentValueOption,
      paymentData.isPartialCoupon
    ]
  );

  const handleFinancingPayment = useCallback(async () => {
    if (!(payments.length > 0)) {
      return;
    }

    setLoading(true);
    setDisabledNext(true);

    const paymentDetails = payments[0].detail;

    const financingInfo = {
      price: paymentDetails.totalInitialInstallmentValue,
      contractId: paymentDetails.contractId
    };

    const { requestId } = paymentDetails;

    const body = buildFinancingPSERequest(financingInfo, payerInfo, requestId);

    const transactionResponse = await CreateFinancingTransactionAPI(
      authToken,
      body
    );

    if (transactionResponse.success) {
      const transaction = transactionResponse.data.data;

      if (transaction.status === TRANSACTION_STATUS.Pending) {
        window.location.href = transaction.bankUrl;
        return;
      }
      history.replace(
        `${ROUTE_NAMES.transaction}/${transaction.transactionUuid}`
      );

      return;
    }

    const error = extractErrorMessage(transactionResponse);
    setAlert({
      type: ALERT_TYPE.ERROR,
      message: error.message
    });

    setDisabledNext(false);
    setLoading(false);
  }, [authToken, payments, payerInfo, setAlert]);

  const handleSetPayment = useCallback((value, scrollRef) => {
    setPayments(value);
    if (scrollRef) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, []);

  const handlePaymentByType = useCallback(
    async evt => {
      switch (type) {
        case TYPE_OF_PAYMENTS.financing:
          return handleFinancingPayment();
        case TYPE_OF_PAYMENTS.contract:
        case TYPE_OF_PAYMENTS.coupon:
          if (payments.length === 1) {
            setLoading(true);
            const couponId = await getCouponIdHandler(evt);
            setLoading(false);
            return handlePsePayment(couponId);
          }
          return handlePsePayment(generatedGroupedCoupon.couponId);
        default:
          return;
      }
    },
    [
      generatedGroupedCoupon,
      handleFinancingPayment,
      handlePsePayment,
      payments,
      type,
      getCouponIdHandler
    ]
  );

  const handleRemoveInvalidCardsAndForward = () => {
    setCardIndividualLoad(prev => ({
      ...prev,
      loading: [],
      paid: [],
      pending: [],
      error: []
    }));

    setCardMultipleLoad(prev => ({
      ...prev,
      loading: [],
      paid: [],
      pending: [],
      error: []
    }));

    setAlert({
      type: ALERT_TYPE.SUCCESS,
      timeout: 5000,
      toast: true,
      message: 'Han sido removidos exitosamente'
    });

    handleTransactionCase();
    setOpenDialog(false);
  };

  const handleTransactionCase = useCallback(() => {
    const currentPaymentsString = JSON.stringify(payments);
    const totalCurrentCardsString = JSON.stringify(totalCurrentCards);

    const allCardsEqual =
      currentPaymentsString === paymentsCopy &&
      totalCurrentCardsString === totalCurrentCardsCopy;

    if (payments.length === 1) {
      handleGoForward();
    } else if (allCardsEqual) {
      handleGoForward();
    } else {
      handleGroupedCouponPayment();
    }
  }, [
    handleGoForward,
    handleGroupedCouponPayment,
    payments,
    paymentsCopy,
    totalCurrentCards,
    totalCurrentCardsCopy
  ]);

  const handleElementStatus = useCallback(() => {
    const totalInvalidIndividual =
      cardIndividualLoad.loading.length +
      cardIndividualLoad.paid.length +
      cardIndividualLoad.pending.length +
      cardIndividualLoad.error.length;
    const totalInvalidMultiple =
      cardMultipleLoad.loading.length +
      cardMultipleLoad.paid.length +
      cardMultipleLoad.pending.length +
      cardMultipleLoad.error.length;

    if (totalInvalidMultiple + totalInvalidIndividual === 0) {
      return handleTransactionCase();
    }

    return setOpenDialog(true);
  }, [
    cardIndividualLoad.loading.length,
    cardIndividualLoad.paid.length,
    cardIndividualLoad.pending.length,
    cardIndividualLoad.error.length,
    cardMultipleLoad.loading.length,
    cardMultipleLoad.paid.length,
    cardMultipleLoad.pending.length,
    cardMultipleLoad.error.length,
    handleTransactionCase
  ]);

  const handleInsuranceActive = () => {
    setShowInsuranceDialog(false);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const onDownloadCoupon = useCallback(
    async evt => {
      setLoadingPDF(true);
      const couponId = await getCouponIdHandler(evt);

      if (!couponId) {
        setLoadingPDF(false);
        return;
      }

      const { partialData } = paymentData;
      const { paymentValueOption: option, couponId: partialCouponId } =
        partialData || {};

      if (
        partialData &&
        option !== PAYMENT_VALUE_OPTION.TotalCoupon &&
        !partialCouponId
      ) {
        setPayments([
          { ...paymentData, partialData: { ...partialData, couponId } }
        ]);
      }

      const response = await GetCouponPDFAPI(couponId);
      if (!response.success) {
        setLoadingPDF(false);
        setAlert({
          type: 'error',
          message: 'Ocurrió un error, intenta de nuevo'
        });
        return;
      }
      const base64 = response.data.data.pdf;
      const pdfName = `pagos_${Company.shortName.toLowerCase()}_cupon_${couponId}.pdf`;
      saveAsPDF(base64, pdfName);
      setLoadingPDF(false);
    },
    [setAlert, getCouponIdHandler, paymentData]
  );

  const renderDialogActions = () => {
    return (
      <Fragment>
        <BaseButton onClick={() => handleRemoveInvalidCardsAndForward()}>
          Entendido
        </BaseButton>
      </Fragment>
    );
  };
  const renderDialogContent = useCallback(() => {
    return (
      <>
        <Typography className={classes.dialogText}>
          Al avanzar al siguiente paso, la transacción será realizada solo con
          cupones y/o {Company.contractConjugation.regular.plural.main} en
          estado
          <span className={classes.paymentPending}> Por pagar</span>. Los items
          que no cumplan esta condición serán removidos.
        </Typography>
      </>
    );
  }, [classes.dialogText, classes.paymentPending]);

  return (
    <Fragment>
      {loadingPDF && <ModalProgress message="Generando documento" />}
      <div className={clsx([classes.root, classes.stepperContainer])}>
        {openDialog && (
          <BaseDialog
            handleClose={() => setOpenDialog(false)}
            open={openDialog}
            title="Estimado cliente"
            actions={renderDialogActions}
            content={renderDialogContent}
            contentStyle={classes.dialogContent}
            contentSize="small"
          />
        )}
        <Container maxWidth="md" className={classes.stepperContainer}>
          <Stepper steps={PAYMENT_STEPS} currentStep={currentStep} />
        </Container>
        <Container maxWidth="md">
          <StepperProvider
            onSubmit={handlePaymentByType}
            initialStepIndex={params.currentStep || 0}
          >
            <Step>
              <PaymentOptions
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                formId={FORM_ID}
                currentStepIndex={currentStep}
                setDisabledNext={setDisabledNext}
                setNextButtonText={setNextButtonText}
                originRoute={params.fromDeferredPayment}
                backToDeferredPayment={backToDeferredPayment}
                handleElementStatus={handleElementStatus}
                updateStepIndex={updateStepIndex}
                setUpdateStepIndex={setUpdateStepIndex}
                payments={payments}
                handleSetPayment={handleSetPayment}
                number={number}
                setNumber={setNumber}
                type={type}
                setType={setType}
                cardIndividualLoad={cardIndividualLoad}
                setCardIndividualLoad={setCardIndividualLoad}
                cardMultipleLoad={cardMultipleLoad}
                setCardMultipleLoad={setCardMultipleLoad}
                totalCurrentCards={totalCurrentCards}
                totalIndividualCards={totalIndividualCards}
                totalMultipleLoadCards={totalMultipleLoadCards}
                checkedMultiplePayments={checkedMultiplePayments}
                setCheckedMultiplePayments={setCheckedMultiplePayments}
                currentTabIndex={currentTabIndex}
                setCurrentTabIndex={setCurrentTabIndex}
                isMultiplePaymentsParam={isMultiplePaymentsParam}
                canPayPartially={canPayPartially}
                setMethodSelected={setMethodSelected}
                setPaymentValueOption={setPaymentValueOption}
              />
            </Step>
            <Step>
              <>
                <SecondStep
                  formId={FORM_ID}
                  setGoBack={setGoBack}
                  handleGoToCardStep={handleGoToCardStep}
                  handleGoBack={handleGoBack}
                  setCurrentStep={setCurrentStep}
                  setNextButtonText={setNextButtonText}
                  setPayerInfo={setPayerInfo}
                  setInsuranceInfo={setInsuranceInfo}
                  payment={
                    payments.length === 1 ? payments[0] : generatedGroupedCoupon
                  }
                  methodSelected={methodSelected}
                  setMethodSelected={setMethodSelected}
                  setDisabledNext={setDisabledNext}
                  isCardStep={isCardStep}
                  handlePaymentByType={handlePaymentByType}
                  showPlanInfo={showPlanInfo}
                  setShowPlanInfo={setShowPlanInfo}
                  silverPlanPrice={silverPlanPrice}
                  isValidForInsurance={isValidForInsurance}
                  currentUser={currentUser}
                  onDownloadCoupon={onDownloadCoupon}
                  paymentValueOption={paymentValueOption}
                  setPaymentValueOption={setPaymentValueOption}
                  setPayment={handleSetPayment}
                  setLoading={setLoading}
                  isMultiplePayment={checkedMultiplePayments}
                  paymentsCount={payments.length}
                  isFinancing={
                    payments.length &&
                    payments[0].type === TYPE_OF_PAYMENTS.financing
                  }
                />
                <InsuranceDialog
                  open={showInsuranceDialog}
                  imageSrc={
                    isMobileSize ? silverPlanImgMobile : silverPlanImgDesktop
                  }
                  title={<>¡Activa tu seguro!</>}
                  message={
                    <>
                      ¿Te gustaría activar nuestro seguro Plan Silver{' '}
                      <span className={classes.breakText}>
                        {' '}
                        y proteger tu vida?
                      </span>
                    </>
                  }
                  handleClose={() => setShowInsuranceDialog(false)}
                  handleConfirmClick={handlePaymentByType}
                  handleDismissClick={handleInsuranceActive}
                  confirmButtonText="Continuar con el pago"
                  dismissButtonBaseText="Activar mi seguro"
                  variant="contained"
                  buttonProps={{
                    className: classes.insuranceButton
                  }}
                  dismissButtonProps={{
                    variant: 'outlined',
                    className: classes.insuranceButton
                  }}
                />
              </>
            </Step>
          </StepperProvider>
        </Container>
        <FooterStepper
          targetForm={FORM_ID}
          disabledNext={disabledNext}
          goBack={goBack}
          nextButtonText={nextButtonText}
          disabledBackButton={disabledBackButton}
        />
      </div>
      {loading && <FullSizeProgress />}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1
  },
  currentStep: {
    marginBottom: theme.custom.footerHeight.stepper
  },
  stepperContainer: {
    // * Mobile
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('xm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    // * Web
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(),
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  stepperBar: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: 4
    }
  },
  stepperText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 0
    }
  },
  dialogContent: {
    minHeight: 'unset'
  },
  dialogText: {
    fontSize: 14
  },
  paymentPending: {
    fontWeight: 600,
    color: theme.palette.warning.main
  },
  insuranceButton: {
    fontSize: '0.8em',
    marginTop: theme.spacing(2),
    width: '180px'
  },
  breakText: {
    whiteSpace: 'nowrap'
  }
}));

export default PaymentGDC;

// const dummyCreditBody = {
//   creditCard: {
//     ownerId: '12345',
//     ownerTel: '7563126',
//     ownerName: 'John Doe',
//     ownerEmail: 'payer_test@test.com',
//     ownerIdentificationNumber: '5415668464654',
//     paymentMethod: 'VISA',
//     number: '4111111111111111',
//     securityCode: '123',
//     expirationDate: '2022/12'
//   },
//   customer: {
//     id: '9992913912392',
//     fullName: 'APPROVED',
//     email: 'buyer_test@test.com',
//     contactPhone: '7563126',
//     identificationNumber: '5415668464654',
//     address: 'Cra 39 # 66 - 55',
//     city: 'Barranquilla'
//   },
//   product: {
//     couponId: 605051824
//   }
// };
