import React from 'react';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { GridList, GridListTile } from '@material-ui/core';
import ImageButton from '../../../Components/Buttons/ImageButton';
import { CONTRACT_TYPES } from '../../Contracts/contract_enums';

// * ICONS

import InvoiceIconButton from '../../../Components/CustomIcons/IcButtonMyInvoices';
import RevisionButtonIcon from '../../../Components/CustomIcons/RevisionButtonIcon';
import PaymentsIcon from '../../../Components/CustomIcons/PaymentsIcon';
import DuplicatesIconButton from '../../../Components/CustomIcons/IcDuplicatesButton';
import DebtsStatusIcon from '../../../Components/CustomIcons/DebtsStatusIcon';
import OwnershipButton from '../../../Components/CustomIcons/OwnershipIcon';
import SubrogationRequestIcon from '../../../Components/CustomIcons/SubrogationRequestIcon';
import InvoicePartialPaymentIcon from '../../../Components/CustomIcons/InvoicePartialPaymentIcon';

const IndustrialOptions = ({
  classes,
  history,
  selectedContract,
  deviceColumns,
  onOpenDuplicates
}) => {
  return (
    <GridList cellHeight="auto" spacing={16} cols={deviceColumns}>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_payments"
          icon={PaymentsIcon}
          title="Pagos"
          aria-label="Pagos"
          onClick={() =>
            history.push(ROUTE_NAMES.payments, {
              type: 'contract',
              value: selectedContract.id
            })
          }
        />
      </GridListTile>
      {selectedContract.type === CONTRACT_TYPES.Industrial && (
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_invoices"
            icon={InvoicePartialPaymentIcon}
            title="Tarifas"
            aria-label="Tarifas"
            onClick={() => history.push(ROUTE_NAMES.home)}
          />
        </GridListTile>
      )}
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_invoices"
          icon={InvoiceIconButton}
          title="Mis facturas"
          aria-label="Mis facturas"
          onClick={() => history.push(ROUTE_NAMES.invoices)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_duplicates"
          icon={DuplicatesIconButton}
          title={'Generar cupón'}
          aria-label="genera_un_cupon_de_pago"
          onClick={onOpenDuplicates}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_periodic_revision"
          icon={RevisionButtonIcon}
          title="Revisión periódica"
          aria-label="Revisión periódica"
          onClick={() => history.push(ROUTE_NAMES.revision)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_contribution_exemption"
          icon={SubrogationRequestIcon}
          title="Exención de contribución"
          aria-label="Eximir contribución"
          onClick={() => history.push(ROUTE_NAMES.home)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_ownership"
          icon={OwnershipButton}
          title="Cambio de titular"
          aria-label="Cambio de titular"
          onClick={() => history.push(ROUTE_NAMES.home)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_new_branches"
          icon={InvoicePartialPaymentIcon}
          title="Nuevos puntos"
          aria-label="Nuevos puntos"
          onClick={() => history.push(ROUTE_NAMES.home)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_contractStatus"
          icon={DebtsStatusIcon}
          title="Mis deudas"
          aria-label="Mis deudas"
          onClick={() => history.push(ROUTE_NAMES.debtStatus)}
        />
      </GridListTile>
    </GridList>
  );
};

export default IndustrialOptions;
