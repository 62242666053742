import { Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import {
  ChallengeQuestionType,
  ContractChallengeType
} from '../../contract_enums';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import { compareArraysOfObjectsByProp } from '../../../../Utils/Misc/Array';
import QuestionChallenge from './QuestionChallenge';

const getAnswerSchema = key =>
  yup.object({
    [key]: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, 'El valor debe ser alfanumérico')
      .max(20, 'Debe tener 20 caracteres o menos.')
      .required('Ingresa la respuesta')
  });

const getAnswersSchema = schemas => {
  const [firstSchema, ...restSchemas] = schemas;

  if (schemas.length > 1) {
    return firstSchema.concat(...restSchemas);
  }

  return firstSchema;
};

const ContractChallenge = props => {
  const {
    tasks,
    challengeId,
    type,
    loading,
    handleAnswers,
    setCanSubmitAnswers,
    setChallengeAnswers,
    challengeAnswers: currentUserAnswers,
    canSubmitAnswers,
    contract,
    setInvoiceHelp
  } = props;

  const classes = useStyles();

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down(600));

  const schemas = tasks.map(({ id }) => getAnswerSchema(id));
  const answerSchema = getAnswersSchema(schemas);

  const {
    register,
    watch,
    errors,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    validationSchema: answerSchema,
    mode: 'onChange'
  });

  const getInputLabel = questionId => {
    switch (questionId) {
      case ChallengeQuestionType.RecentInvoice: {
        const numberText = isSmallScreen ? 'No.' : 'Número';

        return `${numberText} de consecutivo interno`;
      }
      default:
        return '';
    }
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const renderChallengeByType = (id, description, metadata) => {
    const inputLabel = getInputLabel(id);

    switch (type) {
      case ContractChallengeType.Question: {
        return (
          <QuestionChallenge
            key={id}
            id={id}
            description={description}
            label={inputLabel}
            register={register}
            errors={errors}
            loading={loading}
            setInvoiceHelp={setInvoiceHelp}
            metadata={metadata}
          />
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    const anwers = tasks.map(({ id }) => watch([id])[id]);
    const areThereEmptyFields = anwers.some(answer => !Boolean(answer));

    if (!isValid || areThereEmptyFields) {
      setChallengeAnswers(null);
      setCanSubmitAnswers(false);
      return;
    }

    const userAnswers = tasks.map(chall => {
      const { id } = chall;
      return { ...chall, answer: Number(watch([id])[id]) };
    });

    if (!currentUserAnswers) {
      setChallengeAnswers(userAnswers);
      setCanSubmitAnswers(true);
      return;
    }

    const answersUnchanged = compareArraysOfObjectsByProp(
      userAnswers,
      currentUserAnswers
    );

    if (!answersUnchanged) {
      setChallengeAnswers(userAnswers);
      setCanSubmitAnswers(true);
    }
  }, [
    tasks,
    setCanSubmitAnswers,
    setChallengeAnswers,
    watch,
    canSubmitAnswers,
    currentUserAnswers,
    isValid
  ]);

  const handleAnswersValidation = useCallback(async () => {
    if (!isValid) {
      return;
    }

    await handleAnswers();
  }, [handleAnswers, isValid]);

  return (
    <div className={classes.root}>
      {loading && <ModalProgress message="Estamos validando tus respuestas" />}
      <form id={challengeId} onSubmit={handleSubmit(handleAnswersValidation)}>
        <Typography className={classes.text}>
          Contrato: <strong>{contract.id}</strong>
        </Typography>
        <Typography paragraph className={classes.sectionText}>
          Para continuar, debe responder la(s) siguiente(s) pregunta(s):
        </Typography>
        {tasks.map(({ id, description, metadata }) =>
          renderChallengeByType(id, description, metadata)
        )}
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1)
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  sectionText: {
    margin: theme.spacing(1.5, 0, 3, 0)
  },
  questionContainer: {
    marginBottom: theme.spacing()
  },
  questionText: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default ContractChallenge;
