import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import { Step, StepContent, StepLabel } from '@material-ui/core';
import BaseButton from '../../Buttons/BaseButton';

const VerticalLinearStepper = ({ steps, activeStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.verticalStepper}
      >
        {steps.map(step => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.content}
              {(step.handleNext || step.handleBack) && (
                <div className={classes.actionsContainer}>
                  <div>
                    {step.handleBack && (
                      <BaseButton
                        variant="outlined"
                        disabled={step.disabledBack}
                        onClick={step.handleBack}
                        className={classes.button}
                        color="default"
                      >
                        {step.backLabel || 'Volver'}
                      </BaseButton>
                    )}
                    {step.handleNext && (
                      <BaseButton
                        variant="contained"
                        color="primary"
                        onClick={step.handleNext}
                        className={classes.button}
                        disabled={step.disabledNext}
                      >
                        {step.nextLabel || 'Siguiente'}
                      </BaseButton>
                    )}
                  </div>
                </div>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  verticalStepper: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0
    }
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  backIcon: {
    fill: 'currentColor',
    marginRight: theme.spacing(1)
  },
  '.MuiStepContent-root': {
    [theme.breakpoints.down(340)]: {
      paddingLeft: theme.spacing(3)
    }
  }
}));

export default VerticalLinearStepper;
