import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import _get from 'lodash/get';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
  RevisionHistoryAPI,
  RevisionOrderInfoAPI,
  RevisionRequestAPI
} from '../../../API/Revision/RevisionAPI';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';
import PeriodicRevisionHeader from '../PeriodicRevisionHeader';
import BaseButton from '../../../Components/Buttons/BaseButton';
import Title from '../../../Components/Labels/Title';
import RevisionHistory from '../RevisionHistory';
import TimelineSkeleton from '../../../Components/Timeline/TimelineSkeleton';
import NoRevisionIcon from '../../../Components/CustomIcons/NoRevisionIcon';
import { Company } from '../../../Configs/general';
import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../../Utils/Errors/Errors';
import { formatAddress } from '../../../Utils/Format/Address';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { PeriodicRevisionLink } from '../../../Configs/Links';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import { logoutUser } from '../../../Utils/User/Actions';

const PeriodicRevisionMain = ({ selectedContractInfo }) => {
  const classes = useStyles();
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [infoDialog, setInfoDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revisionData, setRevisionData] = useState(null);
  const [noCertificates, setNoCertificates] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const openInfoDialog = useCallback(() => {
    setInfoDialog(true);
  }, []);

  const linkToMoreInfo = () => {
    OpenNewTab(PeriodicRevisionLink);
  };

  // fetch appointment & order info
  useEffect(() => {
    setOrderData(null);

    let ignoreRequest = false;
    const fetchOrderInfo = async () => {
      setLoadingOrder(true);

      const recaptchaToken = authToken
        ? null
        : await getRecaptchaToken(RecaptchaAction.PeriodicRevisionOrderInfo);
      const orderResponse = await RevisionOrderInfoAPI(
        authToken,
        selectedContractInfo.id,
        recaptchaToken
      );
      if (ignoreRequest) {
        return;
      }
      if (orderResponse.success) {
        setOrderData(orderResponse.data);
        setLoadingOrder(false);
      } else {
        setOrderData(null);
        setLoadingOrder(false);
      }
    };

    fetchOrderInfo();
    return () => {
      ignoreRequest = true;
    };
  }, [refresh, authToken, selectedContractInfo]);

  // * Fetch effect
  useEffect(() => {
    let ignoreRequest = false;
    const fetchData = async () => {
      setLoading(true);

      const recaptchaToken = authToken
        ? null
        : await getRecaptchaToken(RecaptchaAction.PeriodicRevisionHistory);
      const response = await RevisionHistoryAPI(
        authToken,
        selectedContractInfo.id,
        recaptchaToken
      );
      if (ignoreRequest) {
        return;
      }
      if (response.success) {
        setLoading(false);
        setNoCertificates(false);
        setRevisionData(response.data.data);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setRevisionData(null);
        const extractedErrors = extractErrorMessage(response);
        if (extractedErrors.key === 'noCertification') {
          setNoCertificates(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        setNoCertificates(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };

    fetchData();

    return () => {
      ignoreRequest = true;
    };
  }, [
    authToken,
    setCurrentUser,
    setRevisionData,
    setAlert,
    setLoading,
    refresh,
    selectedContractInfo
  ]);

  // * Request
  const revisionRequest = useCallback(
    async data => {
      const body = {
        contractId: selectedContractInfo.id,
        observations: data.observations || '',
        phoneCountryCode: data.phoneCountryCode,
        phone: data.phone,
        firstName: data.firstName,
        lastName: data.lastName
      };

      const recaptchaToken = authToken
        ? null
        : await getRecaptchaToken(RecaptchaAction.PeriodicRevisionRequest);
      const response = await RevisionRequestAPI(
        authToken,
        body,
        recaptchaToken
      );

      if (response.success) {
        setAlert({
          type: 'success',
          message: 'Solicitud de revisión generada exitosamente'
        });
        return { unmounting: false, closeDialog: true };
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return { unmounting: true, closeDialog: true };
      }

      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });

      return { unmounting: false, closeDialog: true };
    },
    [authToken, setCurrentUser, setAlert, selectedContractInfo]
  );

  return (
    <div className={classes.root}>
      {infoDialog && (
        <RequestDialog
          open={infoDialog}
          setDialog={setInfoDialog}
          requestTitle={'Solicitud de Revisión Periódica'}
          requestCallback={revisionRequest}
          withoutObservation={true}
          requestNode={
            <Fragment>
              <Typography className={classes.requestText} paragraph>
                Por este medio solicitarás la revisión y certificación de tu
                instalación con {Company.name}. La solicitud se realizará para{' '}
                {Company.contractConjugation.regular.singular.article}{' '}
                <span className={classes.textBold}>
                  #{selectedContractInfo.id}
                </span>{' '}
                con dirección:{' '}
                <span className={clsx([classes.textBold, classes.textCaps])}>
                  {formatAddress(selectedContractInfo, true)}
                </span>{' '}
              </Typography>
              <Typography className={classes.text}>
                Una vez realizada, nuestros agentes se pondrán en contacto
                contigo para agendarte una cita.
              </Typography>
            </Fragment>
          }
          requestSmallText={
            <span>
              Recuerda que puedes realizar la revisión con otro{' '}
              <span className={classes.linkText} onClick={linkToMoreInfo}>
                Organismo de inspección acreditado
              </span>
              .
            </span>
          }
          disclaimer="A través de esta solicitud únicamente podremos tramitar tu solicitud de revisión periódica. Peticiones, quejas o recursos diferentes a la revisión periódica no serán gestionadas por este medio."
          showNameFields={!currentUser}
        />
      )}
      <Container maxWidth="md">
        <PeriodicRevisionHeader
          orderData={orderData}
          loading={loadingOrder}
          authToken={authToken}
          setAlert={setAlert}
          setRefresh={setRefresh}
          selectedContract={selectedContractInfo}
          setCurrentUser={setCurrentUser}
          linkToMoreInfo={linkToMoreInfo}
          currentUser={currentUser}
        />
        <Grid container className={classes.infoCard}>
          <Typography className={classes.infoTitle}>
            ¿Qué es la Revisión Periódica?
          </Typography>
          <Typography className={classes.infoText} paragraph>
            Es la revisión obligatoria que realiza un organismo de inspección
            acreditado a la instalación interna del servicio de gas natural de
            cada inmueble dentro de un plazo mínimo de 4 años y 7 meses y un
            plazo máximo de 5 años, contados a partir de la última certificación
            de su instalación. Esta inspección se desarrolla en cumplimiento con
            la Resolución 067 de 1995 de la Comisión de Regulación de Energía y
            Gas (CREG), modificada por la Resolución CREG 059 de 2012.
          </Typography>
          <Grid container justifyContent="flex-end">
            <BaseButton
              className={classes.actionButton}
              variant="text"
              size="small"
              onClick={linkToMoreInfo}
            >
              Más información
            </BaseButton>
          </Grid>
        </Grid>
        {!noCertificates && (
          <Title title="Línea de tiempo" className={classes.titleSecond} />
        )}
        {!loading && revisionData && !noCertificates && (
          <RevisionHistory
            openDialog={openInfoDialog}
            data={revisionData}
            disableRequestButton={Boolean(orderData)}
            loadingOrder={loadingOrder}
          />
        )}
        {loading && <TimelineSkeleton />}
        {!noCertificates && !loading && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.noCertificateContainer}
          >
            <NoRevisionIcon className={classes.noCertificateIcon} />
            <Grid item>
              <Typography className={classes.noCertificateTitle}>
                {' '}
                No se encontró certificación
              </Typography>
              <Typography className={classes.noCertificateSubtitle}>
                {Company.contractConjugation.capitalized.singular.article}{' '}
                seleccionado no cuenta con ninguna certificación vigente en el
                sistema. Si quieres evitar la suspensión del servicio crea una
                solicitud y revisaremos tu caso.
              </Typography>
            </Grid>

            {!orderData && (
              <Grid item>
                <BaseButton
                  className={classes.noCertificateButton}
                  variant="outlined"
                  size="small"
                  onClick={openInfoDialog}
                >
                  Solicitar Revisión
                </BaseButton>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: 30
  },
  titleSecond: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing()
  },
  infoCard: {
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.cardDark,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  infoTitle: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: theme.spacing()
  },
  infoText: {
    fontSize: 14,
    textAlign: 'justify'
  },
  textBold: {
    fontWeight: 500
  },
  textCaps: {
    textTransform: 'capitalize'
  },
  linkText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500
  },
  actionButton: {
    fontSize: 14,
    color: theme.palette.color.primary,
    marginRight: -theme.spacing(2),
    marginBottom: -theme.spacing()
  },
  noCertificateContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5)
  },
  noCertificateIcon: {
    height: 210,
    viewBox: '0 0 378.269 250.878',
    marginBottom: theme.spacing(2),
    transform: 'scale(0.75)',
    [theme.breakpoints.up('xm')]: {
      transform: 'scale(0.85)'
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1)'
    }
  },
  noCertificateTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center'
  },
  noCertificateSubtitle: {
    fontSize: 13,
    fontStyle: 'italic',
    textAlign: 'center',
    maxWidth: 650
  },
  noCertificateButton: {
    marginTop: theme.spacing(3)
  },
  requestText: {
    fontSize: 13,
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'justify'
  }
}));

export default PeriodicRevisionMain;
