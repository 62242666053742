import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../Transitions/Transitions';
import BaseDialog from '../BaseDialog';
import { InvoiceHelpType } from '../../../Enums/invoices';

const CouponHelp = require('../../../Assets/images/gdg/invoice_coupon_help_v2.png');
const ContractHelp = require('../../../Assets/images/gdg/invoice_subscription_help_v2.png');

const HelpImage = {
  [InvoiceHelpType.Contract]: ContractHelp,
  [InvoiceHelpType.Coupon]: CouponHelp
};

function InvoiceHelpDialogGDG(props) {
  const { open = true, onClose, type, title = 'Título' } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState('');

  const onImageLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [type]);

  useEffect(() => {
    setImage(HelpImage[type]);
  }, [type]);

  const renderContent = () => {
    return (
      <Fragment>
        <img
          className={classes.image}
          src={image}
          alt={`${type} help`}
          onLoad={onImageLoaded}
        />
      </Fragment>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={() => null}
      content={renderContent}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      contentStyle={classes.dialogContent}
    />
  );
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 580,
      height: 270
    }
  },
  dialogContent: {
    padding: theme.spacing()
  }
}));

export default withWidth()(InvoiceHelpDialogGDG);
