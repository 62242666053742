import React from 'react';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { GridList, GridListTile } from '@material-ui/core';
import ImageButton from '../../../Components/Buttons/ImageButton';
import { CONTRACT_TYPES } from '../../Contracts/contract_enums';

// * ICONS

import InvoiceIconButton from '../../../Components/CustomIcons/IcButtonMyInvoices';
import RevisionButtonIcon from '../../../Components/CustomIcons/RevisionButtonIcon';
import PaymentsIcon from '../../../Components/CustomIcons/PaymentsIcon';
import DuplicatesIconButton from '../../../Components/CustomIcons/IcDuplicatesButton';
import BrillaIcon from '../../../Components/CustomIcons/BrillaIcon';
import DebtsStatusIcon from '../../../Components/CustomIcons/DebtsStatusIcon';
import OwnershipButton from '../../../Components/CustomIcons/OwnershipIcon';
import HealthcareIcon from '../../../Components/CustomIcons/HealthcareIcon';
import SubrogationRequestIcon from '../../../Components/CustomIcons/SubrogationRequestIcon';
import InvoicePartialPaymentIcon from '../../../Components/CustomIcons/InvoicePartialPaymentIcon';

const StandardOptions = ({
  classes,
  selectedContract,
  canPayPartially,
  deviceColumns,
  history,
  onOpenDuplicates,
  goToPartialPayment
}) => {
  return (
    <GridList cellHeight="auto" spacing={16} cols={deviceColumns}>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_payments"
          icon={PaymentsIcon}
          title={'Pagos'}
          aria-label="Pagos"
          onClick={() =>
            history.push(ROUTE_NAMES.payments, {
              type: 'contract',
              value: selectedContract.id
            })
          }
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_invoices"
          icon={InvoiceIconButton}
          title="Mis facturas"
          aria-label="Mis facturas"
          onClick={() => history.push(ROUTE_NAMES.invoices)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_ownership"
          icon={OwnershipButton}
          title="Cambio de titular"
          aria-label="Cambio de titular"
          onClick={() => history.push(ROUTE_NAMES.ownership)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_invoices"
          icon={BrillaIcon}
          title={'Mi cupo Brilla'}
          aria-label="Mi cupo Brilla"
          onClick={() => history.push(ROUTE_NAMES.brilla)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_duplicates"
          icon={DuplicatesIconButton}
          title={'Generar cupón'}
          aria-label="genera_un_cupon_de_pago"
          onClick={onOpenDuplicates}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_contractStatus"
          icon={DebtsStatusIcon}
          title="Mis deudas"
          aria-label="Mis deudas"
          onClick={() => history.push(ROUTE_NAMES.debtStatus)}
        />
      </GridListTile>
      {(selectedContract.type === CONTRACT_TYPES.Residential ||
        selectedContract.type === CONTRACT_TYPES.Commercial) && (
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_periodic_revision"
            icon={RevisionButtonIcon}
            title="Revisión periódica"
            aria-label="Revisión periódica"
            onClick={() => history.push(ROUTE_NAMES.revision)}
          />
        </GridListTile>
      )}
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_insurances"
          icon={HealthcareIcon}
          title="Mis seguros"
          aria-label="Mis seguros"
          onClick={() => history.push(ROUTE_NAMES.insurancesHome)}
        />
      </GridListTile>
      <GridListTile classes={{ tile: classes.optionTile }}>
        <ImageButton
          id="AuthHome_button_subrogation_request"
          icon={SubrogationRequestIcon}
          title="Solicitud de subrogación"
          aria-label="Solicitar subrogación"
          onClick={() => history.push(ROUTE_NAMES.subrogationRequest)}
        />
      </GridListTile>
      {canPayPartially && (
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_invoice_partial_payment"
            icon={InvoicePartialPaymentIcon}
            title="Abonar a tu factura"
            aria-label="Abonar a tu factura"
            onClick={goToPartialPayment}
          />
        </GridListTile>
      )}
    </GridList>
  );
};

export default StandardOptions;
