import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import TextInput from '../../../Components/Inputs/TextInput';
import SelectInput from '../../../Components/Inputs/SelectInput';
import Title from '../../../Components/Labels/Title';
import {
  TYPE_OF_IDENT_OPTIONS,
  COUNTRY_OPTIONS,
  CITIES_OPTION
} from '../payment_enums';

const PaymentsSecondStep = props => {
  const { loading = false } = props;

  // * STATE HOOKS;
  const [hasSameInfo, setSameInfo] = useState(true);
  // const [fullName, setFullName] = useState('');

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  return (
    <div>
      <Title text={'Datos del Comprador'} />
      <Grid container spacing={4} className={classes.formContainer}>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <TextInput
            value={'Juan Roca'}
            onChange={() => null}
            disabled={loading}
            label={'Nombre y apellidos'}
            fullWidth
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <TextInput
            value={'juanroca@gmail.com'}
            onChange={() => null}
            disabled={loading}
            label={'Correo electrónico'}
            fullWidth
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <TextInput
            value={'+57'}
            onChange={() => null}
            disabled={loading}
            label={'Número de telefono'}
            fullWidth
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <SelectInput
            value={'CC'}
            onChange={() => null}
            disabled={loading}
            label={'Tipo de identificación'}
            fullWidth
            options={TYPE_OF_IDENT_OPTIONS}
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <TextInput
            value={'1125274505'}
            onChange={() => null}
            disabled={loading}
            label={'Número de identificación'}
            fullWidth
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <SelectInput
            value={'COL'}
            onChange={() => null}
            disabled={loading}
            label={'País'}
            fullWidth
            options={COUNTRY_OPTIONS}
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <SelectInput
            value={'BAQ'}
            onChange={() => null}
            disabled={loading}
            label={'Ciudad'}
            fullWidth
            options={CITIES_OPTION}
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.inputContainer}>
          <TextInput
            value={'CRA 54 # 96 - 133'}
            onChange={() => null}
            disabled={loading}
            label={'Dirección de facturación'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className={classes.inputContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasSameInfo}
                onClick={() => setSameInfo(!hasSameInfo)}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value="checkedI"
              />
            }
            label="Los datos del comprador son los mismos del pagador"
          />
        </Grid>
      </Grid>
      {!hasSameInfo && (
        <Fragment>
          <Title text={'Datos del Pagador'} />
          <Grid container spacing={4} className={classes.formContainer}>
            <Grid item sm={4} xs={12} className={classes.inputContainer}>
              <TextInput
                value={'John Doe'}
                onChange={() => null}
                disabled={loading}
                label={'Nombre y apellidos'}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12} className={classes.inputContainer}>
              <TextInput
                value={'john_doe@gmail.com'}
                onChange={() => null}
                disabled={loading}
                label={'Correo electrónico'}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12} className={classes.inputContainer}>
              <TextInput
                value={'+57'}
                onChange={() => null}
                disabled={loading}
                label={'Número de telefono'}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12} className={classes.inputContainer}>
              <SelectInput
                value={'CC'}
                onChange={() => null}
                disabled={loading}
                label={'Tipo de identificación'}
                fullWidth
                options={TYPE_OF_IDENT_OPTIONS}
              />
            </Grid>
            <Grid item sm={4} xs={12} className={classes.inputContainer}>
              <TextInput
                value={'1125274505'}
                onChange={() => null}
                disabled={loading}
                label={'Número de identificación'}
                fullWidth
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  inputContainer: {
    // paddingTop: '0 !important',
    // paddingBottom: '0 !important',
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: theme.spacing(),
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  formContainer: {
    marginBottom: theme.spacing(4)
  }
}));

export default PaymentsSecondStep;
