import { config } from '.';
import { getCompanyCompactSlogan } from '../Utils/Company';
import { capitalizeFirstLetter } from '../Utils/Format/Names';
import { doPredicateForAllKeys } from '../Utils/Misc/Object';

const portal = config.general.portal;

const Portal = {
  Gascaribe: 'GDC',
  Gasguajira: 'GDG',
  Efigas: 'EFG',
  TripleA: 'AAA'
};

const PortalCheck = {
  isGascaribe: portal === Portal.Gascaribe,
  isGasguajira: portal === Portal.Gasguajira,
  isEfigas: portal === Portal.Efigas,
  isTripleA: portal === Portal.TripleA
};

const ContractConjugationSingular = 'contrato';
const ContractConjugationPlural = 'contratos';

const SubscriptionConjugationSingular = 'suscripción';
const SubscriptionConjugationPlural = 'suscripciones';

const PolicyConjugationSingular = 'póliza';
const PolicyConjugationPlural = 'pólizas';

const ContractConjugation = {
  singular: {
    main: ContractConjugationSingular,
    article: `el ${ContractConjugationSingular}`,
    demonstrative: `este ${ContractConjugationSingular}`,
    undefinedArticle: `un ${ContractConjugationSingular}`,
    contraction: `del ${ContractConjugationSingular}`,
    other: `otro ${ContractConjugationSingular}`,
    new: `nuevo ${ContractConjugationSingular}`,
    newUndefinedArticle: `un nuevo ${ContractConjugationSingular}`,
    associate: `${ContractConjugationSingular} asociado`,
    ingress: `${ContractConjugationSingular} ingresado`,
    selected: `${ContractConjugationSingular} seleccionado`
  },
  plural: {
    main: ContractConjugationPlural,
    article: `los ${ContractConjugationPlural}`,
    demonstrative: `estos ${ContractConjugationPlural}`,
    undefinedArticle: `unos ${ContractConjugationPlural}`,
    contraction: `de los ${ContractConjugationPlural}`,
    other: `otros ${ContractConjugationPlural}`,
    new: `nuevos ${ContractConjugationPlural}`,
    newUndefinedArticle: `unos nuevos ${ContractConjugationPlural}`,
    associate: `${ContractConjugationPlural} asociados`,
    ingress: `${ContractConjugationPlural} ingresados`,
    selected: `${ContractConjugationPlural} seleccionados`
  }
};

const SubscriptionConjugation = {
  singular: {
    main: SubscriptionConjugationSingular,
    article: `la ${SubscriptionConjugationSingular}`,
    demonstrative: `esta ${SubscriptionConjugationSingular}`,
    undefinedArticle: `una ${SubscriptionConjugationSingular}`,
    contraction: `de la ${SubscriptionConjugationSingular}`,
    other: `otra ${SubscriptionConjugationSingular}`,
    new: `nueva ${SubscriptionConjugationSingular}`,
    newUndefinedArticle: `una nueva ${SubscriptionConjugationSingular}`,
    associate: `${SubscriptionConjugationSingular} asociada`,
    ingress: `${SubscriptionConjugationSingular} ingresada`,
    selected: `${SubscriptionConjugationSingular} seleccionada`
  },
  plural: {
    main: SubscriptionConjugationPlural,
    article: `las ${SubscriptionConjugationPlural}`,
    demonstrative: `estas ${SubscriptionConjugationPlural}`,
    undefinedArticle: `unas ${SubscriptionConjugationPlural}`,
    contraction: `de las ${SubscriptionConjugationPlural}`,
    other: `otras ${SubscriptionConjugationPlural}`,
    new: `nuevas ${SubscriptionConjugationPlural}`,
    newUndefinedArticle: `unas nuevas ${SubscriptionConjugationPlural}`,
    associate: `${SubscriptionConjugationPlural} asociadas`,
    ingress: `${SubscriptionConjugationPlural} ingresadas`,
    selected: `${SubscriptionConjugationPlural} seleccionadas`
  }
};

const PolicyConjugation = {
  singular: {
    main: PolicyConjugationSingular,
    article: `la ${PolicyConjugationSingular}`,
    demonstrative: `esta ${PolicyConjugationSingular}`,
    undefinedArticle: `una ${PolicyConjugationSingular}`,
    contraction: `de la ${PolicyConjugationSingular}`,
    other: `otra ${PolicyConjugationSingular}`,
    new: `nueva ${PolicyConjugationSingular}`,
    newUndefinedArticle: `una nueva ${PolicyConjugationSingular}`,
    associate: `${PolicyConjugationSingular} asociada`,
    ingress: `${PolicyConjugationSingular} ingresada`,
    selected: `${PolicyConjugationSingular} seleccionada`
  },
  plural: {
    main: PolicyConjugationPlural,
    article: `las ${PolicyConjugationPlural}`,
    demonstrative: `estas ${PolicyConjugationPlural}`,
    undefinedArticle: `unas ${PolicyConjugationPlural}`,
    contraction: `de las ${PolicyConjugationPlural}`,
    other: `otras ${PolicyConjugationPlural}`,
    new: `nuevas ${PolicyConjugationPlural}`,
    newUndefinedArticle: `unas nuevas ${PolicyConjugationPlural}`,
    associate: `${PolicyConjugationPlural} asociadas`,
    ingress: `${PolicyConjugationPlural} ingresadas`,
    selected: `${PolicyConjugationPlural} seleccionadas`
  }
};

const ContractPseudonyms = {
  [Portal.Gascaribe]: ContractConjugation,
  [Portal.Gasguajira]: SubscriptionConjugation,
  [Portal.Efigas]: ContractConjugation,
  [Portal.TripleA]: PolicyConjugation
};

const CompanyName = {
  [Portal.Gascaribe]: 'Gases del Caribe',
  [Portal.Gasguajira]: 'Gases de La Guajira',
  [Portal.Efigas]: 'Efigas',
  [Portal.TripleA]: 'Triple A'
};

const CompanyShortName = {
  [Portal.Gascaribe]: 'Gascaribe',
  [Portal.Gasguajira]: 'Gasguajira',
  [Portal.Efigas]: 'Efigas',
  [Portal.TripleA]: 'Triple A'
};

const CompanySlogan = {
  [Portal.Gascaribe]: 'Estamos contigo',
  [Portal.Gasguajira]: 'Más calidad de vida',
  [Portal.Efigas]: 'Ahí siempre',
  [Portal.TripleA]: ''
};

const CompanyTributaryIdentifierNumber = {
  [Portal.Gascaribe]: 8901016912,
  [Portal.Gasguajira]: 8921150366,
  [Portal.Efigas]: 8002023953,
  [Portal.TripleA]: 8001359131
};

const CompanyEmergencyPhoneLines = {
  [Portal.Gascaribe]: '01 8000 915 334',
  [Portal.Gasguajira]: '01 8000 911 300',
  [Portal.Efigas]: '01 8000 966 344',
  [Portal.TripleA]: ''
};

const DefaultLocation = {
  [Portal.Gascaribe]: {
    lat: 10.994982,
    lng: -74.793583
  },
  [Portal.Efigas]: {
    lat: 5.0520817,
    lng: -75.482525
  },
  [Portal.Gasguajira]: {
    lat: 11.540039372116446,
    lng: -72.91623387538502
  }
};

const EmergencyNumber = [
  { text: '164', phone: '164' },
  {
    text: CompanyEmergencyPhoneLines[portal],
    phone: CompanyEmergencyPhoneLines[portal].split(' ').join('')
  }
];

const CompanyAttentionLines = {
  [Portal.Efigas]: [
    {
      label: 'Consultas y Solicitudes:',
      number: [
        { text: 'Caldas: 606 898 23 23', phone: '6068982323' },
        { text: 'Quindio: 606 736 89 50', phone: '6067368950' },
        { text: 'Risaralda: 606 351 53 51', phone: '6063515351' }
      ]
    },
    {
      label: 'Reporte de fugas y emergencias:',
      number: EmergencyNumber
    }
  ],
  [Portal.Gascaribe]: [
    {
      label: 'Consultas y Solicitudes:',
      number: [{ text: '(605) 322 7000', phone: '6053227000' }]
    },
    {
      label: 'Reporte de fugas y emergencias:',
      number: EmergencyNumber
    }
  ],
  [Portal.Gasguajira]: [
    {
      label: 'Consultas, solicitudes, reportes o emergencias:',
      number: EmergencyNumber
    }
  ]
};

const Company = {
  name: CompanyName[portal],
  shortName: CompanyShortName[portal],
  tributaryIdentifierNumber: CompanyTributaryIdentifierNumber[portal],
  emergencyPhoneLine: CompanyEmergencyPhoneLines[portal],
  slogan: {
    main: CompanySlogan[portal],
    compact: getCompanyCompactSlogan(CompanySlogan[portal])
  },
  contractConjugation: {
    regular: ContractPseudonyms[portal],
    capitalized: doPredicateForAllKeys(
      ContractPseudonyms[portal],
      capitalizeFirstLetter
    )
  },
  location: DefaultLocation[portal],
  attentionLine: CompanyAttentionLines[portal]
};

const DEFAULT_MAX_MB_SIZE_PQR_FILES = 50;
const MaxMBSizePqrFiles = {
  [Portal.Gascaribe]: DEFAULT_MAX_MB_SIZE_PQR_FILES,
  [Portal.Gasguajira]: 5,
  [Portal.Efigas]: DEFAULT_MAX_MB_SIZE_PQR_FILES,
  [Portal.TripleA]: DEFAULT_MAX_MB_SIZE_PQR_FILES
};

const maxMBSizePqrFiles = MaxMBSizePqrFiles[portal];

export { portal, Portal, PortalCheck, Company, maxMBSizePqrFiles };
