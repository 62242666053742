import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext
} from 'react';
import clsx from 'clsx';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Title from '../../../../Components/Labels/Title';
import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';
import TextInput from '../../../../Components/Inputs/TextInput';
import SelectInput from '../../../../Components/Inputs/SelectInput';
import Recaptcha from '../../../../Components/Captcha/Recaptcha';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';

import NewPaymentCard from '../../../../Components/Cards/NewPaymentCard/NewPaymentCard';

import {
  PAYMENT_VALUE_OPTION,
  SELECT_OPTIONS,
  TYPE_OF_PAYMENTS,
  getCouponValue
} from '../../payment_enums';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

// * API
import { GetCouponAPI } from '../../../../API/Coupons/CouponsAPI';
import { GetInvoicesAPI } from '../../../../API/Invoices/InvoicesAPI';
import { defaultAlertError } from '../../../../Components/Alerts/AlertMessages';
import QuestionMark from '../../../../Components/Adornments/QuestionMark';
import InvoiceHelpDialog from '../../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';

// * Utils
import { HELP_TEXT, COUPON_VALUES } from '../../../../Utils/enums';

import { config } from '../../../../Configs';
import { Company } from '../../../../Configs/general';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import { ALERT_TYPE } from '../../../../Components/Alerts/alert_enums';
import { ValidatePartialPaymentAPI } from '../../../../API/Debts/DebtsAPI';
import { RecaptchaAction } from '../../../../Enums/recaptcha';
import { getRecaptchaToken } from '../../../../Utils/Recaptcha';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const paymentReferenceSchema = yup.object({
  number: yup.string().required('Ingrese el número de contrato'),
  paymentMethod: yup
    .string()
    .oneOf([TYPE_OF_PAYMENTS.coupon, TYPE_OF_PAYMENTS.contract])
    .trim()
    .required('Debes seleccionar una opción válida')
});

const contractSingularDemostrative =
  Company.contractConjugation.capitalized.singular.demonstrative;

const IndividualPaymentGDG = props => {
  const {
    number,
    setDisabledNext,
    setNumber,
    type,
    setType,
    payment,
    setPayment,
    canPayPartially,
    setPaymentValueOption,
    setMethodSelected,
    currentUser
  } = props;

  const setAlert = useContext(AlertsDispatchContext);

  // * STATE HOOKS;
  const [captcha, setCaptcha] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const inputRef = useRef(null);
  const recaptchaRef = useRef(null);

  const canSubmit = !loading && captcha && number && type;

  // * OTHER HOOKS
  const classes = useStyles();

  const { watch, register, setValue, control, handleSubmit, errors } = useForm({
    validationSchema: paymentReferenceSchema,
    defaultValues: { paymentMethod: type, number }
  });

  const paymentMethod = watch('paymentMethod');
  const paymentNumber = watch('number');

  useEffect(() => {
    const formIsEmpty = !paymentNumber || loading || !payment || !paymentMethod;

    if (formIsEmpty || payment.detail.isPaid || payment.detail.isPending) {
      setDisabledNext(true);
    } else {
      // PayU does not allow us to pay coupons with such a small value (100 COP),
      // so disable the Next button when that happens.
      const value = getCouponValue(payment);

      const canPay = value >= COUPON_VALUES.MIN_VALUE;
      setDisabledNext(!canPay);
      if (!canPay) {
        setAlert({
          type: 'info',
          message: `Solo se permite realizar pagos mayores a ${COUPON_VALUES.MIN_VALUE} pesos.`
        });
      }
    }
  }, [
    paymentMethod,
    paymentNumber,
    loading,
    payment,
    setDisabledNext,
    setAlert
  ]);

  // * FUNCTIONS
  useEffect(() => {
    setType(paymentMethod);
    setPayment([]);
    setNumber('');
    setValue('number', null);
  }, [paymentMethod, setPayment, setType, setNumber, setValue]);

  useEffect(() => {
    setPayment([]);
    setNumber(paymentNumber);
  }, [paymentNumber, setNumber, setPayment]);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const onSubmit = async () => {
    if (!canSubmit) {
      return;
    }

    // set states
    setLoading(true);
    setPayment([]);
    setMethodSelected('');
    setPaymentValueOption('');

    setDisabledNext(true);

    const captchaValue = captcha;

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setCaptcha(null);
    }
    let paymentData;

    // api fetch
    if (type === TYPE_OF_PAYMENTS.coupon) {
      const { success, data, error } = await GetCouponAPI(number, captchaValue);
      if (success) {
        const couponData = data.data;
        paymentData = [
          {
            type: TYPE_OF_PAYMENTS.coupon,
            detail: {
              ...couponData,
              couponId: couponData.id,
              couponValue: couponData.value
            }
          }
        ];
      } else {
        defaultAlertError(error, setAlert, 'couponId');
        setLoading(false);
        return;
      }
    } else if (type === TYPE_OF_PAYMENTS.contract) {
      const { success, data, error } = await GetInvoicesAPI(
        number,
        captchaValue
      );

      if (success) {
        paymentData = [
          { type: TYPE_OF_PAYMENTS.contract, detail: data.data[0] }
        ];
      } else {
        defaultAlertError(error, setAlert, 'contractId');
        setLoading(false);
        return;
      }
    }

    const { couponId, contractId } = paymentData[0].detail;

    const userToken = currentUser ? currentUser.token : null;

    let recaptchaToken;
    if (!userToken) {
      recaptchaToken = await getRecaptchaToken(
        RecaptchaAction.ValidatePartialPayments
      );
    }

    const validateResponse = await ValidatePartialPaymentAPI(
      contractId,
      couponId,
      userToken,
      recaptchaToken
    );

    if (validateResponse.success) {
      const {
        valid,
        canPayPartially: canPayPartial,
        isPartialCoupon
      } = validateResponse.data.data;
      paymentData = [
        {
          ...paymentData[0],
          canBePaidPartially: canPayPartial && valid,
          isPartialCoupon
        }
      ];
    } else {
      paymentData = [{ ...paymentData[0], canBePaidPartially: false }];
    }

    setPaymentValueOption(
      paymentData[0].canBePaidPartially ? '' : PAYMENT_VALUE_OPTION.TotalCoupon
    );

    setPayment(paymentData);

    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  return (
    <Fragment>
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={type}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT[type]}
      />
      {loadingCaptcha && (
        <ModalProgress
          id={'QueryPayment_progress_loadingCaptcha'}
          message={'Cargando'}
        />
      )}
      <Container
        maxWidth="md"
        className={`${classes.root} ${
          loadingCaptcha ? classes.captchaLoading : ''
        }`}
      >
        {loading && (
          <ModalProgress
            id={'QueryPayment_progress_loading'}
            message={'Buscando'}
          />
        )}
        <Grid container spacing={3} className={classes.formContainer}>
          <Grid item md={4} sm={4} xs={12} className={classes.inputContainer}>
            <Controller
              as={<SelectInput />}
              id="paymentMethod"
              label={'Referencia'}
              name="paymentMethod"
              control={control}
              error={Boolean(errors.paymentMethod)}
              helperText={errors.paymentMethod && errors.paymentMethod.message}
              fullWidth
              options={SELECT_OPTIONS}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="number"
              inputRef={register}
              control={control}
              name="number"
              autoFocus
              disabled={loading}
              error={Boolean(errors.number)}
              label={`Número ${
                paymentMethod === TYPE_OF_PAYMENTS.contract
                  ? `de ${Company.contractConjugation.regular.singular.main}`
                  : 'del cupón'
              }`}
              InputProps={{
                endAdornment: <QuestionMark onClick={onHelpClick} />,
                inputProps: {
                  type: 'number',
                  inputMode: 'numeric'
                }
              }}
              required={true}
              helperText={
                errors && errors.number
                  ? errors.number.message || errors.number.types.message
                  : ''
              }
              fullWidth
            />
          </Grid>
          <Grid
            item
            sm={4}
            xs={12}
            ref={inputRef}
            className={clsx([classes.inputContainer])}
          >
            <Recaptcha
              captchaRef={recaptchaRef}
              referenceEl={inputRef}
              locale={'es'}
              heightScale={0.75}
              sitekey={config.recaptcha.siteKey}
              onResolved={onCaptchaResolved}
              onExpired={onCaptchaExpired}
              onLoaded={onCaptchaLoaded}
            />
          </Grid>
          <Grid item xs={12} className={classes.consultContainer}>
            <BaseButton
              id="QueryPayment_button_submit"
              onClick={handleSubmit(onSubmit)}
              disabled={!canSubmit}
              fullWidth={!isWidthUp('sm', props.width)}
              color="primary"
              variant="outlined"
            >
              Consultar
            </BaseButton>
          </Grid>
        </Grid>
        <SimpleDivider withoutMargin />
        {payment && (
          <div style={{ marginBottom: '80px' }}>
            <Title
              text={`Detalle ${
                payment.type === TYPE_OF_PAYMENTS.coupon
                  ? 'del cupón'
                  : 'de la factura'
              }`}
              className={classes.resultTitle}
            />
            <NewPaymentCard
              type={payment.type}
              newTariff={false}
              onChangeNewTariff={() => null}
              details={{
                ...payment.detail
              }}
            />
            {canPayPartially && (
              <SweetAlert
                type={ALERT_TYPE.INFO}
                classes={{ root: classes.sweetAlert }}
                message={
                  <>
                    {contractSingularDemostrative} puede realizar abonos a su
                    deuda actual. Si la deuda cumple con las condiciones para
                    abonar, al continuar al siguiente paso encontrarás la opción
                    de pago <b>Otro valor</b>.
                  </>
                }
              />
            )}
          </div>
        )}
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    padding: theme.spacing(0),
    marginBottom: 200,
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  },
  consultContainer: {
    textAlign: 'end',
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingBottom: '0 !important'
    },
    padding: theme.spacing(2, 0)
  },
  inputContainer: {
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: theme.spacing(),
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    },
    padding: 0
  },
  recaptchaContainer: {
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: 24
    }
  },
  captchaWeb: {
    // * Mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  resultTitle: {
    marginTop: theme.spacing(3)
  },
  formContainer: {
    marginBottom: theme.spacing(2)
  },
  captchaMobile: {
    // * Mobile
    paddingTop: theme.spacing(5),
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  captchaLoading: {
    visibility: 'hidden'
  },
  sweetAlert: {
    marginTop: theme.spacing(2)
  }
}));

export default withWidth()(IndividualPaymentGDG);
