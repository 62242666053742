import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import ClientInfoForm from './ClientInfoForm';
import CardInfoForm from './CardInfoForm';

import NewPaymentCard from '../../../Components/Cards/NewPaymentCard/NewPaymentCard';
import PseInfoForm from './PseInfoForm/PseInfoForm';
import InsuranceAcquisitionCard from '../../../Components/Cards/InsuranceAcquisitionCard';
import {
  PAYMENT_METHOD,
  PAYMENT_VALUE_OPTION,
  TYPE_OF_PAYMENTS,
  getPaymentMethodLabel
} from '../payment_enums';
import NewCouponCard from '../../../Components/Cards/NewCouponCard';
import PaymentValueOptions from './PaymentValueOptions';
import PaymentMethodOptions from './PaymentMethod';
import PseImage from '../../../Assets/images/pse_logo.png';
import DuplicateButtonIcon from '../../../Components/CustomIcons/IcDuplicatesButton';
import {
  CheckPartialPaymentAPI,
  ValidatePartialPaymentAPI
} from '../../../API/Debts/DebtsAPI';
import { ValidPartialPaymentProductTypeIds } from '../../Debts/myDebts_enums';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import { useMediaQuery } from '@material-ui/core';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import {
  CurrentStepIndexContext,
  SetCurrentStepIndexContext
} from '../../../Contexts/StepperContext';
import { useForm } from 'react-hook-form';

const INSURANCE_VALID_VALUE_OPTIONS = [
  PAYMENT_VALUE_OPTION.TotalCoupon,
  PAYMENT_VALUE_OPTION.CurrentDebt
];

const PaymentsSecondStep = props => {
  const {
    formId,
    setGoBack,
    handleGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    handleGoToCardStep,
    handlePaymentByType,
    payment,
    methodSelected,
    isCardStep,
    setPayerInfo,
    setInsuranceInfo,
    setDisabledNext,
    setShowPlanInfo,
    showPlanInfo,
    silverPlanPrice = null,
    isValidForInsurance = false,
    currentUser,
    setMethodSelected,
    onDownloadCoupon,
    setPaymentValueOption,
    paymentValueOption,
    setPayment,
    isMultiplePayment,
    paymentsCount,
    isFinancing
  } = props;

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);

  // * STATE HOOKS;
  const [isDisabledPse, setIsDisabledPse] = useState(false);
  const [isDisabledInsurance, setIsDisabledInsurance] = useState(false);
  const [loading, setLoading] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const svgSize = isMobileSize ? 98 : 64;

  const { handleSubmit } = useForm({});

  // * FUNCTIONS

  const onBackward = useCallback(() => {
    handleGoBack();
    setCurrentStep(currentStep => currentStep - 1);
  }, [setCurrentStep, handleGoBack]);

  const onForward = useCallback(() => {
    if (methodSelected === PAYMENT_METHOD.CARD) {
      handleGoToCardStep();
    } else {
      handlePaymentByType();
    }
  }, [handlePaymentByType, handleGoToCardStep, methodSelected]);

  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
    setDisabledNext(true);
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setDisabledNext
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [paymentValueOption]);

  useEffect(() => {
    if (isFinancing) {
      return;
    }
    let ignoreRequest = false;

    const fetch = async () => {
      if (ignoreRequest) {
        return;
      }

      setLoading(true);

      const { contractId, couponId } = payment.detail;
      const token = currentUser ? currentUser.token : null;

      let recaptchaToken = await getRecaptchaToken(
        RecaptchaAction.ValidatePartialPayments
      );

      const validateResponse = await ValidatePartialPaymentAPI(
        contractId,
        couponId,
        token,
        recaptchaToken
      );

      let partialPaymentValidation = {};
      if (validateResponse.success) {
        const {
          valid,
          canPayPartially: canPayPartial,
          isPartialCoupon
        } = validateResponse.data.data;

        partialPaymentValidation = {
          canBePaidPartially: canPayPartial && valid,
          isPartialCoupon
        };

        if (!valid) {
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
        return;
      }

      recaptchaToken = await getRecaptchaToken(
        RecaptchaAction.CheckPartialPayments
      );

      const checkResponse = await CheckPartialPaymentAPI(
        contractId,
        ValidPartialPaymentProductTypeIds,
        couponId,
        token,
        recaptchaToken
      );

      let paymentData;

      if (checkResponse.success) {
        const debtData = checkResponse.data.data;
        paymentData = [{ ...payment, debtData, ...partialPaymentValidation }];
      } else {
        paymentData = [{ ...payment, canBePaidPartially: false }];
      }

      setPaymentValueOption(
        paymentData[0].canBePaidPartially
          ? ''
          : PAYMENT_VALUE_OPTION.TotalCoupon
      );

      setPayment(paymentData);
      setLoading(false);
    };

    const { canBePaidPartially, debtData } = payment;

    if (
      !payment.hasOwnProperty('canBePaidPartially') ||
      (canBePaidPartially && !debtData)
    ) {
      fetch();
    }

    return () => {
      ignoreRequest = true;
    };
  }, [
    currentUser,
    payment,
    setPayment,
    setLoading,
    setPaymentValueOption,
    isFinancing
  ]);

  useEffect(() => {
    setDisabledNext(isDisabledPse || isDisabledInsurance);
  }, [isDisabledPse, isDisabledInsurance, setDisabledNext]);

  useEffect(() => {
    if (!Object.values(PAYMENT_METHOD).includes(methodSelected)) {
      setIsDisabledPse(true);
    }
  }, [methodSelected, setIsDisabledPse]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const showInsuranceCard =
    isValidForInsurance &&
    silverPlanPrice &&
    INSURANCE_VALID_VALUE_OPTIONS.includes(paymentValueOption) &&
    !payment.isPartialCoupon &&
    Boolean(methodSelected);

  const renderStep = () => {
    if (isCardStep) {
      return <CardInfoForm />;
    }

    return (
      <Fragment>
        <form id={formId} onSubmit={handleSubmit(onForward)}>
          {methodSelected === PAYMENT_METHOD.CARD && (
            <ClientInfoForm
              setDisabledNext={setDisabledNext}
              setPayerInfo={setPayerInfo}
            />
          )}
          {methodSelected === PAYMENT_METHOD.PSE && (
            <PseInfoForm
              setDisabledNext={setIsDisabledPse}
              setPayerInfo={setPayerInfo}
              showTitle={false}
              extraPadding={!showInsuranceCard}
            />
          )}
        </form>
      </Fragment>
    );
  };

  const renderPaymentCard = () => {
    const useOldCard =
      (isMultiplePayment && paymentsCount > 1) || payment.detail.couponId < 0;

    if (useOldCard) {
      return (
        <div>
          <NewPaymentCard details={payment.detail} type={payment.type} />
        </div>
      );
    }

    if (payment.type !== TYPE_OF_PAYMENTS.couponless) {
      return (
        <div>
          <NewCouponCard detail={payment.detail} type={payment.type} />
        </div>
      );
    }
  };

  const onClickPseMethodButton = useCallback(() => {
    if (methodSelected !== PAYMENT_METHOD.PSE) {
      setMethodSelected(PAYMENT_METHOD.PSE);
    }
  }, [setMethodSelected, methodSelected]);

  const paymentMethods = useMemo(
    () => [
      {
        id: 'Payment_method_pse_image_button',
        label: getPaymentMethodLabel(PAYMENT_METHOD.PSE),
        image: PseImage,
        onClick: onClickPseMethodButton,
        selected: methodSelected === PAYMENT_METHOD.PSE
      },
      {
        id: 'Payment_method_print_coupon_image_button',
        label: 'Imprimir cupón',
        image: <DuplicateButtonIcon width={svgSize} height={svgSize} />,
        onClick: onDownloadCoupon,
        isSvg: true,
        hidden: (isMultiplePayment && paymentsCount > 1) || isFinancing
      }
    ],
    [
      methodSelected,
      onClickPseMethodButton,
      onDownloadCoupon,
      isMultiplePayment,
      paymentsCount,
      svgSize,
      isFinancing
    ]
  );

  const showPaymentMethods = () => {
    if (paymentValueOption === PAYMENT_VALUE_OPTION.Partial) {
      if (
        payment.partialData &&
        payment.partialData.paymentValueOption === PAYMENT_VALUE_OPTION.Partial
      ) {
        return true;
      }
    } else if (
      Object.values(PAYMENT_VALUE_OPTION).includes(paymentValueOption)
    ) {
      return true;
    }

    return false;
  };

  const renderPaymentOptions = () => {
    return (
      <PaymentValueOptions
        payment={payment}
        setPayment={setPayment}
        currentUser={currentUser}
        setMethodSelected={setMethodSelected}
        paymentValueOption={paymentValueOption}
        setPaymentValueOption={setPaymentValueOption}
      />
    );
  };

  const renderPaymentMethods = () => {
    if (showPaymentMethods()) {
      return <PaymentMethodOptions methods={paymentMethods} />;
    }

    return null;
  };

  const renderInsuranceCard = () => {
    if (showInsuranceCard) {
      return (
        <div>
          <InsuranceAcquisitionCard
            setDisabledNext={setIsDisabledInsurance}
            setInsuranceInfo={setInsuranceInfo}
            price={silverPlanPrice}
            showPlanInfo={showPlanInfo}
            setShowPlanInfo={setShowPlanInfo}
          />
        </div>
      );
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      {loading ? (
        <ModalProgress
          id={'QueryCurrentDebtdata_progress_loading'}
          message={'Consultando tus deudas'}
        />
      ) : (
        <>
          {renderPaymentCard()}
          {renderPaymentOptions()}
          {renderPaymentMethods()}
          {renderStep()}
          {renderInsuranceCard()}
        </>
      )}
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(12),
    paddingRight: theme.spacing(4),
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(12)
    }
  },
  inputContainer: {
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: theme.spacing(),
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  selectableButtons: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    }
  }
}));

export default PaymentsSecondStep;
