import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../Transitions/Transitions';
import BaseDialog from '../BaseDialog';
import clsx from 'clsx';
import { InvoiceHelpType } from '../../../Enums/invoices';

const CouponHelp = require('../../../Assets/images/efg/invoice_coupon_help_efg_v2.png');
const ContractHelp = require('../../../Assets/images/efg/invoice_contract_help_efg_v2.png');

const HelpImage = {
  [InvoiceHelpType.Contract]: ContractHelp,
  [InvoiceHelpType.Coupon]: CouponHelp
};

function InvoiceHelpDialogEFG(props) {
  const { open = true, onClose, type, title = 'Título' } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState('');

  const onImageLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [type]);

  useEffect(() => {
    setImage(HelpImage[type]);
  }, [type]);

  const renderContent = () => {
    return (
      <Fragment>
        <img
          className={clsx({ [classes.image]: isMobileSize })}
          src={image}
          alt={`${type} help`}
          onLoad={onImageLoaded}
        />
      </Fragment>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={() => null}
      content={renderContent}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      contentStyle={isMobileSize ? null : classes.contentStyle}
      paperClass={classes.paperClass}
    />
  );
}

const useStyles = makeStyles(() => ({
  image: {
    width: '100%'
  },
  contentStyle: {
    padding: 10,
    justifyContent: 'center',
    display: 'flex',
    height: 500
  }
}));

export default withWidth()(InvoiceHelpDialogEFG);
