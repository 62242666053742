import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo
} from 'react';
import _get from 'lodash/get';
import clsx from 'clsx';
import { Grid, Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import FolderIcon from '../../../Components/CustomIcons/FolderIcon';
import BackIcon from '../../../Components/CustomIcons/BackIcon';
import EyeIcon from '../../../Components/CustomIcons/EyeIcon';
import PointsIcon from '../../../Components/CustomIcons/IcPointsFireBadge';
import PhoneValidation from '../../Profile/PhoneValidation';
import CallToActionDialog from '../../../Components/Dialogs/CallToActionDialog';
import ContractAssociationDialog from '../../Contracts/ContractAssociationDialog/ContractAssociationDialog';
import ContractAssociationIcon from '../../../Components/CustomIcons/ContractAssociationIcon';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import SweetAlert from '../../../Components/Alerts/SweetAlert';
import InvoiceCard from '../../../Components/Cards/InvoiceCard/InvoiceCard';
import FlatButton from '../../../Components/Buttons/FlatButton';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  ContractsContext,
  ContractSelectedContext
} from '../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { fullName } from '../../../Utils/Format/Names';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import { REVISION_PERIOD_TYPE } from '../../../Utils/enums';
import { HEADER_MAP_ICON } from '../../Layout/Header/header_enums';

import { GetInvoicesAPI } from '../../../API/Invoices/InvoicesAPI';
import { RevisionAPI } from '../../../API/Revision/RevisionAPI';
import { GetSingleContractAPI } from '../../../API/Contracts/ContractsAPI';

import NoContractBanner from '../Banners/NoContractBanner';
import DuplicateDialogs from '../../Duplicates/Duplicates';

import { useFetchCommunications } from '../../../API/Communications/useFetchCommunications';
import SlideShow from '../../../Components/SlideShow/SlideShow';
import GameContext from '../../../Contexts/GameContext';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import { CONTRACT_TYPES } from '../../Contracts/contract_enums';
import { toast } from 'react-toastify';
import {
  CheckPartialPaymentAPI,
  GeneratePartialPaymentCouponAPI,
  GetNegotiationPreApprovalAPI
} from '../../../API/Debts/DebtsAPI';
import {
  doesPhoneNeedsRevalidation,
  logoutUser
} from '../../../Utils/User/Actions';
import { Company } from '../../../Configs/general';
import PartialPaymentIcon from '../../../Components/CustomIcons/PartialPaymentIcon';
import DebtPartialPaymentDialog from '../../Debts/DebtPartialPayment/DebtPartialPaymentDialog';
import GroupedButtons from '../../../Components/Buttons/GroupedButtons';
import { MonetizationOn } from '@material-ui/icons';
import { TYPE_OF_PAYMENTS } from '../../Payment/payment_enums';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import { ValidPartialPaymentProductTypeIds } from '../../Debts/myDebts_enums';
import HomeOptions from '../HomeOptions/HomeOptions.GDC';

const AuthHomeGDC = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const authToken = _get(currentUser, 'token');

  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);

  // * STATE HOOKS
  const [invoice, setInvoice] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [phoneUpdate, setPhoneUpdate] = useState(false);
  const [duplicatesOpen, setDuplicatesOpen] = useState(false);
  const [revisionDeadline, setRevisionDeadline] = useState(null);
  const [gameLoading, setGameLoading] = useState(false);
  const [contract, setContract] = useState(null);
  const [contractLoading, setContractLoading] = useState(false);
  const [associationSuggestionOpen, setAssociationSuggestionOpen] = useState(
    false
  );
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);
  const [openBrillaPartialPayment, setOpenBrillaPartialPayment] = useState(
    false
  );
  const [loadingDebt, setLoadingDebt] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallSize = useMediaQuery('(max-width:450px)');
  const isMediumSize = useMediaQuery('(max-width:650px)');
  const [communications, getCommunications] = useFetchCommunications();
  const toastIdRef = useRef(null);

  const canPayPartially = useMemo(
    () => invoice && selectedContract && selectedContract.canPayPartially,
    [invoice, selectedContract]
  );

  const notify = useCallback(() => {
    const toastMsg = () => (
      <Typography>
        ¡Tienes pre aprobado un acuerdo de pago para este{' '}
        {Company.contractConjugation.regular.singular.main}!{' '}
        <strong>Haz clic aquí y solicítalo.</strong>
      </Typography>
    );

    toastIdRef.current = toast.info(toastMsg, {
      onClick: () => history.push(ROUTE_NAMES.currentDebtSummary),
      autoClose: false
    });
  }, []);

  // Fetch pre approval negotiation
  useEffect(() => {
    if (!selectedContract) {
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      const contractId = selectedContract.id;
      const response = await GetNegotiationPreApprovalAPI(
        authToken,
        contractId
      );

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        const { preApproved } = response.data.data;
        if (preApproved) {
          notify();
        }
      }
    };

    if (selectedContract.type === CONTRACT_TYPES.Residential) {
      fetchData();
    }

    return () => {
      ignoreRequest = true;
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    };
  }, [authToken, selectedContract, setAlert, notify]);

  // Fetch invoices data
  useEffect(() => {
    if (!selectedContract) {
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      setInvoiceLoading(true);
      const contractId = selectedContract.id;
      const response = await GetInvoicesAPI(contractId, '-', authToken);

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        setInvoice(response.data.data[0]);
        setInvoiceLoading(false);
      } else {
        setInvoice(null);
        setInvoiceLoading(false);

        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
      }
    };
    fetchData();
    return () => {
      ignoreRequest = true;
    };
  }, [selectedContract, authToken, setCurrentUser, setAlert]);

  // Fetch periodic revision status data
  useEffect(() => {
    if (!selectedContract) {
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      const contractId = selectedContract.id;
      const response = await RevisionAPI(authToken, contractId);

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        setRevisionDeadline(response.data.data);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        const extractedErrors = extractErrorMessage(response);
        if (extractedErrors.key === 'noCertification') {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };
    setRevisionDeadline(null);
    fetchData();
    return () => {
      ignoreRequest = true;
    };
  }, [selectedContract, authToken, setCurrentUser, setAlert]);

  // Fetch banner communications if any
  useEffect(() => {
    getCommunications(authToken);
  }, [getCommunications, authToken]);

  const downloadInvoice = useCallback(() => {
    OpenNewTab(invoice.url);
  }, [invoice]);

  // * FUNCTIONS

  const isAssociated = useCallback(
    contractId => {
      return contracts.some(c => c.id === contractId);
    },
    [contracts]
  );

  const onOpenDuplicates = useCallback(() => {
    setDuplicatesOpen(true);
  }, []);

  const onCloseDuplicates = useCallback(
    (contractId = null) => {
      setContract(contractId);
      setAssociationSuggestionOpen(contractId && !isAssociated(contractId));
      setDuplicatesOpen(false);
    },
    [isAssociated]
  );

  const handleOpenBrillaPartialPayment = useCallback(() => {
    setOpenBrillaPartialPayment(open => !open);
  }, [setOpenBrillaPartialPayment]);

  const fetchContract = useCallback(
    async contractId => {
      setContractLoading(true);
      const response = await GetSingleContractAPI(contractId, authToken);
      setContractLoading(false);
      if (response.success) {
        setContract(response.data.data);
        setAssociationDialogOpen(true);
      } else {
        setAssociationDialogOpen(false);
      }
    },
    [authToken]
  );

  const goToPartialPayment = useCallback(async () => {
    if (!invoice) {
      return;
    }

    if (invoice.isPaid) {
      setAlert({
        type: ALERT_TYPE.INFO,
        message: 'No puedes abonar debido a que tu factura ya está pagada'
      });
      return;
    }

    setLoadingDebt(true);

    const response = await CheckPartialPaymentAPI(
      selectedContract.id,
      ValidPartialPaymentProductTypeIds,
      invoice.couponId,
      authToken
    );

    if (response.success) {
      setLoadingDebt(false);
      const debtData = response.data.data;

      history.push(ROUTE_NAMES.payments, {
        type: TYPE_OF_PAYMENTS.contract,
        value: selectedContract.id,
        currentStep: 1,
        disabledNext: true,
        payment: [
          {
            type: TYPE_OF_PAYMENTS.contract,
            canBePaidPartially: true,
            debtData,
            detail: { ...invoice }
          }
        ]
      });
    } else {
      setLoadingDebt(false);
      setAlert({
        type: ALERT_TYPE.ERROR,
        message: extractErrorMessage(response).message
      });
      return;
    }
  }, [selectedContract, invoice, setAlert, authToken]);

  const _renderHomeTitle = () => {
    if (contracts.length === 0) {
      return (
        <Grid
          container
          className={clsx(classes.cardTitle, classes.cardTitleNoContract)}
          onClick={() => {
            history.push(ROUTE_NAMES.associate);
          }}
        >
          <Grid className={classes.contractTitle}>
            <FolderIcon size={20} />
            <Typography className={classes.cardTitleText}>
              Asociar {Company.contractConjugation.regular.plural.main}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container className={classes.cardTitle}>
        <Grid className={classes.contractTitle}>
          {HEADER_MAP_ICON[selectedContract.icon]({
            size: 20,
            className: classes.iconBlue
          })}
          {selectedContract && (
            <Typography className={classes.cardTitleText}>
              {!isMobileSize &&
                `${Company.contractConjugation.capitalized.singular.main} - `}
              <strong>{selectedContract.alias}</strong>
            </Typography>
          )}
        </Grid>

        <Grid className={classes.contractTitle}>
          {selectedContract && (
            <Grid>
              <Typography className={classes.pointsTitle}>
                {isMobileSize ? 'Mis puntos' : 'Puntos Puntualízate'}
              </Typography>
              <Typography className={classes.points}>
                {selectedContract.points} puntos
              </Typography>
            </Grid>
          )}
          <PointsIcon size={32} />
        </Grid>
      </Grid>
    );
  };

  const _actionRedirect = () => {
    if (!invoice) {
      return;
    }
    history.push(ROUTE_NAMES.payments, {
      type: 'contract',
      value: selectedContract.id,
      currentStep: 1,
      disabledNext: false,
      payment: [{ type: 'contract', detail: invoice }]
    });
  };

  const _goToVerifyVisit = () => {
    history.push(ROUTE_NAMES.verifyVisit);
  };

  const _renderHomeBanner = () => {
    if (contracts.length === 0) {
      return (
        <NoContractBanner
          contractCompanyReferer={
            Company.contractConjugation.regular.singular.undefinedArticle
          }
        />
      );
    }

    const hasUrl = invoice && invoice.url && invoice.url.includes('watermark');

    const pendingInvoice =
      !invoiceLoading && invoice && !invoice.isPaid && !invoice.isPending;

    const groupedButtons = [
      {
        label: 'Ver factura',
        icon: EyeIcon,
        onClick: downloadInvoice,
        hidden: !hasUrl
      },
      {
        label: 'Abonar a Brilla',
        icon: PartialPaymentIcon,
        onClick: handleOpenBrillaPartialPayment
      },
      {
        label: 'Pagar factura',
        icon: MonetizationOn,
        onClick: _actionRedirect
      }
    ];

    return (
      <Fragment>
        <InvoiceCard
          invoiceDetails={invoice}
          className={classes.invoiceCard}
          loading={invoiceLoading}
          showTotalTooltip
          showCurrentDebtButton
        />

        {isMediumSize && (
          <GroupedButtons
            buttons={groupedButtons}
            customClassName={{
              root: classes.groupedButtonsRoot,
              buttonContent: classes.groupedButtonContent,
              label: classes.groupedButtonsLabel
            }}
            isHidden={!pendingInvoice}
          />
        )}

        {pendingInvoice && !isMediumSize && (
          <Grid container className={classes.invoiceActions}>
            {hasUrl && (
              <Grid>
                <FlatButton
                  id="AuthHome_button_downloadInvoice"
                  color="primary"
                  className={classes.actionButton}
                  onClick={downloadInvoice}
                >
                  Ver factura
                  <EyeIcon size={16} className={classes.iconLeftStyle} />
                </FlatButton>
              </Grid>
            )}
            <Grid item>
              <FlatButton
                id="AuthHome_button_brillaPartialPayment"
                color="primary"
                className={classes.actionButton}
                onClick={handleOpenBrillaPartialPayment}
              >
                Abonar a Brilla
                <PartialPaymentIcon
                  height={16}
                  width={16}
                  className={classes.partialPaymentIcon}
                />
              </FlatButton>
            </Grid>
            <Grid item>
              <FlatButton
                id="AuthHome_button_actionRedirect"
                color="primary"
                className={classes.actionButton}
                onClick={_actionRedirect}
              >
                Pagar factura
                <BackIcon size={16} className={classes.iconRightStyle} />
              </FlatButton>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  };

  const onUpdatePhone = useCallback(
    async event => {
      event.preventDefault();
      setPhoneUpdate({ submit: true });
    },
    [setPhoneUpdate]
  );

  const _renderPhoneValidation = useCallback(() => {
    const needsRevalidation = doesPhoneNeedsRevalidation(
      currentUser.phoneValidationDate
    );
    if (
      (!currentUser.phone || currentUser.phoneValidated) &&
      !needsRevalidation
    ) {
      return;
    }

    return (
      <Fragment>
        <SweetAlert
          id="AuthHome_div_phoneValidation_info"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          type={needsRevalidation ? 'warning' : 'info'}
          message={
            <Fragment>
              {needsRevalidation ? 'Queremos seguir en contacto contigo. ' : ''}
              Presiona <strong>aquí</strong> para validar tu número de celular
              (+{currentUser.phoneCountryCode} {currentUser.phone}).
            </Fragment>
          }
          onClick={onUpdatePhone}
        />
        <PhoneValidation
          setAlert={setAlert}
          phoneUpdate={phoneUpdate}
          setPhoneUpdate={setPhoneUpdate}
          phone={currentUser.phone}
          phoneCountryCode={currentUser.phoneCountryCode}
        />
      </Fragment>
    );
  }, [classes, currentUser, onUpdatePhone, phoneUpdate, setAlert]);

  const _renderCompleteUserRegister = useCallback(() => {
    if (currentUser.identification) {
      return;
    }

    return (
      <Fragment>
        <SweetAlert
          id="AuthHome_div_userRegister_info"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              Presiona <strong>aquí</strong> para completar tu perfil y
              aprovechar todas las funcionalidades que tenemos para ti.
            </Fragment>
          }
          onClick={() => history.push(ROUTE_NAMES.profile, true)}
        />
      </Fragment>
    );
  }, [classes, currentUser]);

  const _renderRevisionWarning = useCallback(() => {
    if (!revisionDeadline || contracts.length === 0) {
      return;
    }

    if (revisionDeadline.period === REVISION_PERIOD_TYPE.Effective) {
      return;
    }

    if (revisionDeadline.period === REVISION_PERIOD_TYPE.Review) {
      return (
        <SweetAlert
          id="AuthHome_div_periodic_warning"
          type="warning"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              El certificado de revisión periódica de tu{' '}
              {Company.contractConjugation.regular.singular.main} está{' '}
              <strong>a punto de vencerse</strong>. Para evitar la suspensión
              del servicio, solicita tu revisión <strong>aquí</strong>.
            </Fragment>
          }
          onClick={() => history.push(ROUTE_NAMES.revision)}
        />
      );
    }

    if (revisionDeadline.period === REVISION_PERIOD_TYPE.Suspension) {
      return (
        <SweetAlert
          id="AuthHome_div_periodic_warning"
          type="error"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              Tu {Company.contractConjugation.regular.singular.main} se
              encuentra en estado de <strong>suspensión inmediata</strong> del
              servicio de gas. Para evitar la suspensión, solicita la revisión
              periódica <strong> aquí</strong>.
            </Fragment>
          }
          onClick={() => history.push(ROUTE_NAMES.revision)}
        />
      );
    }

    return;
  }, [revisionDeadline, contracts, classes]);

  return (
    <Container
      id="AuthHome_div_container"
      maxWidth="sm"
      className={classes.authContainer}
    >
      {loadingDebt && <ModalProgress message="Consultando tus deudas" />}
      {openBrillaPartialPayment && (
        <DebtPartialPaymentDialog
          open={openBrillaPartialPayment}
          onClose={handleOpenBrillaPartialPayment}
          requestCallback={GeneratePartialPaymentCouponAPI}
          paymentData={{ contractId: selectedContract.id }}
          description={
            <Typography className={classes.dialogDescription}>
              Ingresa el valor que deseas abonar. Recuerda que este cupón se
              aplicará a tu deuda que ya ha sido facturada del producto Brilla
              (servicios financieros).
            </Typography>
          }
        />
      )}
      {duplicatesOpen && (
        <DuplicateDialogs open={duplicatesOpen} onClose={onCloseDuplicates} />
      )}
      {associationSuggestionOpen && (
        <CallToActionDialog
          open={associationSuggestionOpen}
          icon={ContractAssociationIcon}
          title={`¡Asocia ${Company.contractConjugation.regular.singular.demonstrative} y gestiónalo fácilmente!`}
          message={`Asocia ${Company.contractConjugation.regular.singular.demonstrative} a tu cuenta y así ahorrarás tiempo en tus próximas transacciones`}
          confirmButtonText={`Asociar ${Company.contractConjugation.regular.singular.main}`}
          handleConfirmClick={() => {
            setAssociationSuggestionOpen(false);
            fetchContract(contract);
          }}
          handleClose={() => {
            setAssociationSuggestionOpen(false);
          }}
        />
      )}

      {associationDialogOpen && (
        <ContractAssociationDialog
          open={associationDialogOpen}
          handleClose={() => {
            setAssociationDialogOpen(false);
          }}
          contract={contract}
          setAlert={setAlert}
        />
      )}
      <Typography id="AuthHome_text_welcome" className={classes.titleText}>
        Bienvenido,{' '}
        <strong>{fullName(currentUser, 'firstName', 'lastName', true)}</strong>
      </Typography>

      <GameContext.Provider value={setGameLoading}>
        <SlideShow
          slides={communications}
          contractId={selectedContract && selectedContract.id}
        />
      </GameContext.Provider>

      {(gameLoading || contractLoading) && <FullSizeProgress />}
      {_renderRevisionWarning()}
      {_renderPhoneValidation()}
      {_renderCompleteUserRegister()}
      <Grid container className={classes.homeCardContainer}>
        <Grid item>{_renderHomeTitle()}</Grid>
        <Grid item>{_renderHomeBanner()}</Grid>
        <Grid item className={classes.optionsContainer}>
          <HomeOptions
            classes={classes}
            isSmallSize={isSmallSize}
            isMediumSize={isMediumSize}
            contracts={contracts}
            selectedContract={selectedContract}
            history={history}
            canPayPartially={canPayPartially}
            goToPartialPayment={goToPartialPayment}
            onOpenDuplicates={onOpenDuplicates}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" className={classes.visit}>
        Consulta información de tu visita{' '}
        <span className={classes.here} onClick={_goToVerifyVisit}>
          aquí
        </span>
      </Typography>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  authContainer: {
    maxWidth: '700px',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  titleText: {
    fontSize: '1em',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2)
  },
  authCloserGrid: {
    display: 'flex',
    boxSizing: 'border-box',
    margin: [[theme.spacing(4), theme.spacing(2)]]
  },
  mapIcon: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2)
  },
  authCloserText: {
    fontSize: '1em'
  },
  link: {
    color: '#424242',
    fontWeight: 500,
    textDecoration: 'none'
  },
  homeCardContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '6px',
    border: 'solid 1px #e7e7e7',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden'
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  cardTitleNoContract: {
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  contractTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  pointsTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'end',
    marginRight: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  points: {
    fontSize: 13,
    lineHeight: 1,
    textAlign: 'end',
    marginRight: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  cardTitleText: {
    fontSize: 14,
    marginLeft: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 15
    }
  },
  invoiceCard: {
    border: '0',
    borderRadius: 0,
    borderTop: 'solid 1px #e7e7e7',
    borderBottom: 'solid 1px #e7e7e7'
  },
  invoiceActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.cardDark,
    borderBottom: 'solid 1px #e7e7e7',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  actionButton: {
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.common.black,
    textTransform: 'none',
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  iconLeftStyle: {
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  partialPaymentIcon: {
    marginLeft: theme.spacing(),
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main
  },
  invoiceActionsSkeleton: {
    width: '25%'
  },
  optionsContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3.5)
  },
  optionTile: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'start'
  },
  optionButton: {
    borderRadius: '12px',
    position: 'relative',
    /* For Safari 3.1 to 6.0 */
    '-webkit-transition-duration': '0.3s',
    transitionDuration: '0.3s',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      transform: 'scale(1.08)'
    }
  },
  closerButton: {
    cursor: 'pointer',
    fontWeight: 600,
    verticalAlign: 'unset',
    display: 'unset',
    '&:hover': {
      color: theme.palette.color.primary
    }
  },
  sweetAlert: {
    marginBottom: 16,
    cursor: 'pointer'
  },
  sweetAlertText: {
    fontSize: 14
  },
  visit: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    paddingTop: theme.spacing(4),
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.75em'
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '0.83em'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1em',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    }
  },
  here: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    fontWeight: 600,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  groupedButtonContent: {
    minHeight: 'auto'
  },
  groupedButtonsRoot: {
    borderWidth: '0 0 1px 0',
    borderRadius: 0,
    backgroundColor: theme.palette.background.cardDark
  },
  groupedButtonsLabel: {
    color: theme.palette.common.black
  }
}));

export default AuthHomeGDC;
