/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';
import TextInput from '../../../../Components/Inputs/TextInput';
import SelectInput from '../../../../Components/Inputs/SelectInput';
import Title from '../../../../Components/Labels/Title';

import {
  TYPE_OF_IDENT_OPTIONS,
  TYPE_OF_CUSTOMER,
  DEFAULT_BANK_VALUE,
  PAYMENT_METHOD
} from '../../payment_enums';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import {
  TERMS,
  TERMS_ROUTES
} from '../../../Information/Information/Information';
import { GetBanksListAPI } from '../../../../API/Payments/PaymentsAPI';
import { UserContext } from '../../../../Contexts/UserContext';
import FlagColombia from '../../../../Components/CustomIcons/Flags/FlagColombia';

import {
  mapBanksList,
  pseInfoSchema,
  findBankName,
  getFormDefaultValues
} from './PseInfoForm';
import { PrivacyPoliciesLink } from '../../../../Configs/Links';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import NumberInput from '../../../../Components/Inputs/NumberInput';

const PseInfoFormGDG = props => {
  const {
    loading = false,
    setPayerInfo,
    setDisabledNext,
    showTitle = true,
    showPlanInfo = false
  } = props;
  // * CONTEXTS
  const currentUser = useContext(UserContext);

  // * OTHER HOOKS
  const classes = useStyles();

  // * INPUTS

  const { register, errors, watch, control } = useForm({
    validationSchema: pseInfoSchema,
    mode: 'onBlur',
    submitFocusError: true,
    defaultValues: getFormDefaultValues(currentUser)
  });

  const {
    email,
    payerFullName,
    typeOfIdentification,
    identification,
    phoneCountryCode,
    phone,
    typeOfPerson,
    currentBank
  } = watch([
    'email',
    'payerFullName',
    'typeOfIdentification',
    'identification',
    'phoneCountryCode',
    'phone',
    'typeOfPerson',
    'currentBank'
  ]);

  const [acceptsTerms, setAcceptsTerms] = useState(Boolean(currentUser));
  const [banks, setBanks] = useState([
    { label: 'Cargando bancos...', value: DEFAULT_BANK_VALUE }
  ]);
  const [banksLoading, setBanksLoading] = useState(true);

  const onChangeAcceptTerms = useCallback(() => {
    if (currentUser) {
      return;
    }

    setAcceptsTerms(!acceptsTerms);
  }, [currentUser, acceptsTerms]);

  useEffect(() => {
    let ignoreRequest = false;
    const fetchBanks = async () => {
      if (ignoreRequest) {
        return;
      }
      setBanksLoading(true);
      const response = await GetBanksListAPI();
      setBanksLoading(false);

      if (response.success) {
        setBanks(mapBanksList(response.data.data.banks));
      }
    };

    fetchBanks();

    return () => {
      ignoreRequest = true;
    };
  }, []);

  useEffect(() => {
    if (
      !email ||
      !payerFullName ||
      !typeOfIdentification ||
      !identification ||
      !phone ||
      !phoneCountryCode ||
      !currentBank ||
      currentBank === DEFAULT_BANK_VALUE ||
      !typeOfPerson ||
      !acceptsTerms
    ) {
      setDisabledNext(true);
      return;
    }
    setDisabledNext(false);
  }, [
    email,
    payerFullName,
    typeOfIdentification,
    identification,
    phoneCountryCode,
    phone,
    typeOfPerson,
    currentBank,
    acceptsTerms,
    currentUser,
    setDisabledNext
  ]);

  useEffect(() => {
    if (!banks || banks.length === 0) {
      setPayerInfo(null);
      return;
    }

    setPayerInfo({
      type: PAYMENT_METHOD.PSE,
      email,
      payerFullName,
      typeOfIdentification,
      identification,
      phone,
      phoneCountryCode,
      currentBank,
      currentBankName: findBankName(banks, currentBank),
      typeOfPerson
    });
  }, [
    email,
    payerFullName,
    typeOfIdentification,
    identification,
    phone,
    phoneCountryCode,
    currentBank,
    typeOfPerson,
    setPayerInfo,
    banks
  ]);

  return (
    <Fragment>
      {banksLoading && <ModalProgress id="PseInfoForm_modal_loading" />}
      <div
        className={clsx(classes.formRoot, {
          [classes.extraPaddingBottom]: !showPlanInfo
        })}
      >
        {showTitle && (
          <Title text={'Información del pago'} className={classes.pageTitle} />
        )}
        <Grid container spacing={4} className={classes.formContainer}>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="email"
              placeholder="correo@ejemplo.com"
              name="email"
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              disabled={loading}
              label={'Correo electrónico'}
              className={
                errors && Boolean(errors.email)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              inputRef={register}
              autoFocus
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <Controller
              as={<SelectInput />}
              id="typeOfPerson"
              name="typeOfPerson"
              error={Boolean(errors.typeOfPerson)}
              helperText={errors.typeOfPerson && errors.typeOfPerson.message}
              disabled={loading}
              label={'Tipo de cliente'}
              className={
                errors && Boolean(errors.typeOfPerson)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              options={TYPE_OF_CUSTOMER}
              control={control}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <Controller
              as={<SelectInput />}
              id="currentBank"
              name="currentBank"
              error={Boolean(errors.currentBank)}
              helperText={errors.currentBank && errors.currentBank.message}
              disabled={loading}
              label={'Banco'}
              className={
                errors && Boolean(errors.currentBank)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              options={banks}
              control={control}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="payerFullName"
              name="payerFullName"
              error={Boolean(errors.payerFullName)}
              helperText={errors.payerFullName && errors.payerFullName.message}
              disabled={loading}
              label={'Nombre del pagador'}
              className={
                errors && Boolean(errors.payerFullName)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              inputRef={register}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <Controller
              as={<SelectInput />}
              id="typeOfIdentification"
              name="typeOfIdentification"
              error={Boolean(errors.typeOfIdentification)}
              helperText={
                errors.typeOfIdentification &&
                errors.typeOfIdentification.message
              }
              disabled={loading}
              label={'Tipo de identificación'}
              defaultValue={
                (currentUser && currentUser.identificationType) || 'CC'
              }
              className={
                errors && Boolean(errors.typeOfIdentification)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              options={TYPE_OF_IDENT_OPTIONS}
              control={control}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="identification"
              name="identification"
              error={Boolean(errors.identification)}
              helperText={
                errors.identification && errors.identification.message
              }
              disabled={loading}
              label={'Número de identificación'}
              className={
                errors && Boolean(errors.identification)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              inputRef={register}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <Controller
              as={
                <NumberInput
                  id="PseInfoForm_input_phoneCountryCode"
                  autoComplete="tel-country-code"
                  InputProps={{
                    startAdornment: (
                      <FlagColombia size={20} style={{ flexShrink: 0 }} />
                    )
                  }}
                  format="+###"
                  placeholder="57"
                  fullWidth
                  label="Prefijo telefónico"
                  error={Boolean(errors.phoneCountryCode)}
                  helperText={
                    errors.phoneCountryCode && errors.phoneCountryCode.message
                  }
                  margin="none"
                  className={
                    errors && Boolean(errors.phoneCountryCode)
                      ? classes.inputError
                      : classes.input
                  }
                />
              }
              control={control}
              name="phoneCountryCode"
              defaultValue={
                currentUser && currentUser.phoneCountryCode
                  ? currentUser.phoneCountryCode
                  : '57'
              }
              onChangeName="onValueChange"
              onChange={values => {
                if (values[0]) {
                  return values[0].value;
                }
                return '';
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <Controller
              as={
                <NumberInput
                  id="PseInfoForm_input_phone"
                  autoComplete="tel-local"
                  fullWidth
                  margin="none"
                  label="Número celular"
                  error={Boolean(errors.phone)}
                  helperText={errors.phone && errors.phone.message}
                />
              }
              control={control}
              name="phone"
              onChangeName="onValueChange"
              onChange={values => {
                if (values[0]) {
                  return values[0].value;
                }
                return '';
              }}
            />
          </Grid>
          {!Boolean(currentUser) && (
            <Grid item xs={12} className={classes.termsContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="acceptsTerms"
                    checked={acceptsTerms}
                    onClick={onChangeAcceptTerms}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    disabled={Boolean(currentUser)}
                    value="checkedI"
                  />
                }
                label={
                  <Typography className={classes.terms}>
                    He leído y acepto los{' '}
                    <a
                      className={classes.link}
                      href={`${ROUTE_NAMES.information}/${
                        TERMS_ROUTES[TERMS.introduction]
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      términos y condiciones
                    </a>{' '}
                    de uso, la{' '}
                    <a
                      href={PrivacyPoliciesLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      política y aviso de privacidad
                    </a>{' '}
                    y autorizo el tratamiento de mis datos personales.
                  </Typography>
                }
              />
            </Grid>
          )}
        </Grid>
        <SimpleDivider />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(6)
    }
  },
  extraPaddingBottom: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(6)
    }
  },
  pageTitle: {
    marginTop: 0,
    fontSize: 14,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  },
  formContainer: {
    paddingTop: theme.spacing(2)
  },
  inputContainer: {
    marginBottom: theme.spacing(1.2),
    paddingBottom: '0 !important',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.2),
      paddingTop: '0 !important'
    }
  },
  termsContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0 !important'
    }
  },
  terms: {
    fontSize: 14
  },
  input: {
    marginBottom: 17
  },
  inputError: {
    marginBottom: 0
  }
}));

export default PseInfoFormGDG;
