/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useState,
  useCallback,
  useRef
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Grow from '@material-ui/core/Grow';

import * as yup from 'yup';

import BaseButton from '../Buttons/BaseButton';
import BaseDialog from '../Dialogs/BaseDialog';
import NumberInput from '../Inputs/NumberInput';
import TextInput from '../Inputs/TextInput';
import { SlideUpTransition } from '..//Transitions/Transitions';
import FlagColombia from '../CustomIcons/Flags/FlagColombia';
import PhoneValidation from '../../Views/Profile/PhoneValidation';
import SweetAlert from '../Alerts/SweetAlert';
import { UserContext } from '../../Contexts/UserContext';
import { doesPhoneNeedsRevalidation } from '../../Utils/User/Actions';

import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import PhoneVerificationDialog from '../Dialogs/PhoneVerificationDialog';
import Recaptcha from '../Captcha/Recaptcha';
import { config } from '../../Configs';
import { ROUTE_NAMES } from '../../Routes/Routes';
import {
  TERMS,
  TERMS_ROUTES
} from '../../Views/Information/Information/Information';
import { PrivacyPoliciesLink } from '../../Configs/Links';

const clientInfoSchema = yup.object({
  phone: yup
    .string()
    .trim()
    .required('*Requerido')
    .length(10, 'Debe tener 10 dígitos'),
  phoneCountryCode: yup
    .string()
    .trim()
    .required('*Requerido')
});

const clientNamesSchema = yup.object({
  firstName: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(80, 'Máximo 80 caracteres')
    .trim()
    .required('*Requerido'),
  lastName: yup
    .string()
    .min(2, 'Mínimo 2 caracteres')
    .max(80, 'Máximo 80 caracteres')
    .trim()
    .required('*Requerido')
});

const RequestDialog = props => {
  const {
    open,
    setDialog,
    requestTitle,
    requestCallback,
    requestNode,
    requestNodeAfterForm,
    requestSmallText,
    withoutObservation = false,
    beforeClosing = null,
    backButton = null,
    submitButtonText = 'Solicitar',
    disclaimer,
    showNameFields = false,
    ...other
  } = props;

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);
  // * HOOKS
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [terms, setTerms] = useState(false);

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const defaultValues = {
    phone: (currentUser && currentUser.phone) || '',
    phoneCountryCode: (currentUser && currentUser.phoneCountryCode) || '57'
  };

  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues,
    validationSchema: showNameFields
      ? clientInfoSchema.concat(clientNamesSchema)
      : clientInfoSchema,
    mode: 'onBlur'
  });

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const isInvalidCaptcha =
    loadingCaptcha ||
    captcha === 'disable-captcha-validation' ||
    Boolean(!captcha);

  // * Phone Validation
  const phone = watch('phone', defaultValues.phone);
  const phoneCountryCode = watch(
    'phoneCountryCode',
    defaultValues.phoneCountryCode
  );

  const [phoneUpdate, setPhoneUpdate] = useState({ submit: false });

  const phoneChanged = useCallback(() => {
    return (
      currentUser.phone !== phone ||
      currentUser.phoneCountryCode !== phoneCountryCode
    );
  }, [currentUser, phone, phoneCountryCode]);

  const phoneNeedsValidation = useCallback(() => {
    if (!currentUser) {
      return true;
    }
    return Boolean(phoneChanged() || !currentUser.phoneValidated);
  }, [phoneChanged, currentUser]);

  const onUpdatePhone = useCallback(() => {
    setPhoneUpdate({ submit: true });
  }, [setPhoneUpdate]);

  const phoneNeedsRevalidation = useCallback(() => {
    if (!currentUser) {
      return false;
    }
    doesPhoneNeedsRevalidation(currentUser.phoneValidationDate);
  }, [currentUser]);

  // * Handle Form Submit

  const canSubmit = phone.length === 10 && phoneCountryCode && !loading;

  const onChangeTerms = useCallback(() => {
    setTerms(!terms);
  }, [terms]);

  const resetCaptcha = useCallback(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setCaptcha(null);
    }
  }, []);

  const onSubmit = useCallback(
    async data => {
      if (!canSubmit) {
        return;
      }

      if (!currentUser) {
        setFormData({
          phone: data.phone,
          phoneCountryCode: data.phoneCountryCode,
          firstName: data.firstName,
          lastName: data.lastName
        });

        setOpenVerificationDialog(true);
        return;
      }

      if (phoneNeedsValidation() || phoneNeedsRevalidation()) {
        onUpdatePhone();
        return;
      }
      setLoading(true);

      const { unmounting, closeDialog } = await requestCallback(data);

      if (unmounting) {
        return;
      }
      setLoading(false);
      if (closeDialog) {
        setDialog(false);
      }
    },
    [
      canSubmit,
      phoneNeedsValidation,
      onUpdatePhone,
      requestCallback,
      setDialog,
      phoneNeedsRevalidation,
      currentUser
    ]
  );

  const successCallback = useCallback(async () => {
    setLoading(true);

    await requestCallback({
      ...formData
    });

    setLoading(false);
    setDialog(false);
  }, [setDialog, requestCallback, formData]);

  const handleClose = useCallback(() => {
    if (beforeClosing) {
      beforeClosing();
    }

    setDialog(false);
  }, [setDialog, beforeClosing]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={backButton ? backButton.handle : handleClose}
          variant="outlined"
          color="primary"
          size="small"
          disabled={loading}
        >
          {backButton ? backButton.text : 'Cancelar'}
        </BaseButton>
        <BaseButton
          onClick={handleSubmit(onSubmit)}
          type="submit"
          color="primary"
          size="small"
          form="RequestDialogForm"
          autoFocus
          disabled={
            !canSubmit || (!currentUser && (isInvalidCaptcha || !terms))
          }
        >
          {phoneNeedsValidation() || phoneNeedsRevalidation()
            ? 'Validar celular'
            : submitButtonText}
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.content}>
        {requestNode}
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="RequestDialogForm"
          className={classes.formContainer}
        >
          <Grid container justifyContent="center" spacing={2}>
            {showNameFields && (
              <>
                <Grid item xs={5} sm={5}>
                  <TextInput
                    id="firstName"
                    inputRef={register}
                    name="firstName"
                    error={Boolean(errors.firstName)}
                    disabled={false}
                    helperText={errors.firstName && errors.firstName.message}
                    label={'Nombres *'}
                    className={
                      errors && Boolean(errors.firstName)
                        ? classes.inputError
                        : classes.input
                    }
                    margin="none"
                    fullWidth
                    autoFocus
                  />
                </Grid>
                <Grid item xs={7} sm={7}>
                  <TextInput
                    id="lastName"
                    inputRef={register}
                    name="lastName"
                    error={Boolean(errors.lastName)}
                    disabled={false}
                    helperText={errors.lastName && errors.lastName.message}
                    label={'Apellidos *'}
                    classlastname={
                      errors && Boolean(errors.lastName)
                        ? classes.inputError
                        : classes.input
                    }
                    margin="none"
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={5} sm={5}>
              <Controller
                as={
                  <NumberInput
                    id="RequestDialog_input_countryCode"
                    autoComplete="tel-country-code"
                    required
                    InputProps={{
                      startAdornment: (
                        <FlagColombia size={20} style={{ flexShrink: 0 }} />
                      )
                    }}
                    format="+###"
                    placeholder="57"
                    fullWidth
                    label="Prefijo"
                    disabled={loading}
                    error={Boolean(errors.phoneCountryCode)}
                    helperText={
                      errors.phoneCountryCode && errors.phoneCountryCode.message
                    }
                    margin="none"
                  />
                }
                control={control}
                name="phoneCountryCode"
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].value;
                  }
                  return '';
                }}
              />
            </Grid>
            <Grid item xs={7} sm={7}>
              <Controller
                as={
                  <NumberInput
                    id="RequestDialog_input_phone"
                    autoComplete="tel-local"
                    required
                    fullWidth
                    margin="none"
                    label="Número celular"
                    disabled={loading}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                }
                control={control}
                name="phone"
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].value;
                  }
                  return '';
                }}
              />
            </Grid>
            {phoneNeedsValidation() && canSubmit && (
              <Grow in={true}>
                <Grid className={classes.sweetAlert} item xs={12}>
                  <SweetAlert
                    id="RequestDialog_div_phoneValidation_info"
                    icon={false}
                    classes={{
                      root: classes.sweetAlert,
                      message: classes.sweetAlertText
                    }}
                    message={
                      <Fragment>
                        Validaremos tu{' '}
                        {currentUser &&
                        currentUser.phoneValidated &&
                        currentUser.phone ? (
                          <strong>nuevo número</strong>
                        ) : (
                          'número'
                        )}{' '}
                        celular antes de continuar.
                      </Fragment>
                    }
                  />
                </Grid>
              </Grow>
            )}
            {phoneNeedsRevalidation() && canSubmit && !phoneNeedsValidation() && (
              <Grow in={true}>
                <Grid className={classes.sweetAlert} item xs={12}>
                  <SweetAlert
                    id="RequestDialog_div_phoneRevalidation_info"
                    icon={false}
                    classes={{
                      root: classes.sweetAlert,
                      message: classes.sweetAlertText
                    }}
                    type="warning"
                    message={
                      <Fragment>
                        Queremos seguir en contacto contigo. Validaremos tu
                        número de celular antes de generar la solicitud.
                      </Fragment>
                    }
                  />
                </Grid>
              </Grow>
            )}
            {!withoutObservation && (
              <Grid item xs={12} sm={12}>
                <TextInput
                  id="RequestDialog_input_observations"
                  inputRef={register}
                  multiline
                  rows={2}
                  label="Observaciones"
                  margin="none"
                  fullWidth
                  name="observations"
                  disabled={loading}
                />
              </Grid>
            )}
          </Grid>
          {!currentUser && (
            <Grid
              container
              direction="column"
              className={classes.termsContainer}
            >
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="Signup_input_terms"
                      checked={terms}
                      onClick={onChangeTerms}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          fontSize="small"
                          color="primary"
                        />
                      }
                      checkedIcon={
                        <CheckBoxIcon fontSize="small" color="primary" />
                      }
                      value="checkedI"
                    />
                  }
                  label={
                    <Typography className={classes.termsText}>
                      He leído y acepto los{' '}
                      <a
                        className={classes.link}
                        href={`${ROUTE_NAMES.information}/${
                          TERMS_ROUTES[TERMS.introduction]
                        }`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        términos y condiciones
                      </a>{' '}
                      de uso, la{' '}
                      <a
                        href={PrivacyPoliciesLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        política y aviso de privacidad
                      </a>{' '}
                      y autorizo el tratamiento de mis datos personales.
                    </Typography>
                  }
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                ref={inputRef}
                className={classes.recaptchaContainer}
              >
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  heightScale={0.75}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </Grid>
            </Grid>
          )}
        </form>
        {requestNodeAfterForm}
        <Typography className={classes.textSmall}>
          {requestSmallText} {disclaimer}
        </Typography>
      </div>
    );
  };

  const isMobileSize = isWidthDown('sm', props.width);

  return (
    <Fragment>
      {openVerificationDialog && (
        <PhoneVerificationDialog
          open={openVerificationDialog}
          setDialog={setOpenVerificationDialog}
          formPhone={formData.phone}
          formPhoneCountryCode={formData.phoneCountryCode}
          title="Validar número celular"
          setAlert={setAlert}
          successCallback={successCallback}
          captchaValue={captcha}
          resetCaptcha={resetCaptcha}
        />
      )}
      <PhoneValidation
        setAlert={setAlert}
        phoneUpdate={phoneUpdate}
        setPhoneUpdate={setPhoneUpdate}
        phone={phone}
        phoneCountryCode={phoneCountryCode}
      />
      <BaseDialog
        className={classes.dialogRoot}
        open={open}
        loading={loading}
        handleClose={handleClose}
        title={requestTitle}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentStyle={classes.dialogContent}
        {...other}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing()
    }
  },
  text: {
    fontSize: 13,
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'justify'
  },
  textSmall: {
    fontSize: 11,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(),
    textAlign: 'justify'
  },
  textBold: {
    fontWeight: 500
  },
  textCaps: {
    textTransform: 'capitalize'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dialogContent: {
    paddingBottom: theme.spacing(2)
  },
  formContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    minWidth: '60%'
  },
  sweetAlert: {
    minWidth: '100%'
  },
  termsContainer: {
    padding: [[22, 0, 0, 0]],
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 0, 0, 0)
    }
  },
  inputContainer: {
    padding: theme.spacing(0, 1)
  },
  recaptchaContainer: {
    padding: 0,
    overflow: 'hidden'
  }
}));

export default withWidth()(RequestDialog);
