import { Company } from '../Configs/general';

export const HELP_TEXT = {
  contract: `¿Dónde puedo ver mi número de ${Company.contractConjugation.regular.singular.main}?`,
  coupon: '¿Dónde puedo ver mi número de cupón?',
  pin: '¿Dónde puedo ver el PIN de mi certificado?',
  invoice: '¿Dónde puedo ver el número de consecutivo interno de mi factura?'
};

export const COUPON_VALUES = {
  MIN_VALUE: 100
};

export const REVISION_TEXT = {
  effectivePeriod:
    'Te encuentras al día con la revisión de tu instalación interna de gas.',
  revisionPeriod:
    'Te encuentras en periodo para solicitar la revisión de tu instalación.',
  suspensionPeriod:
    'Para poder garantizar tu seguridad nos vemos obligados a realizar la suspensión preventiva de tu servicio de gas.',
  avoidSuspension:
    'Si deseas evitar la suspensión, solicita la revisión a continuación.',
  reminder:
    'Recuerda que realizar la revisión periódica garantiza la seguridad de tu familia, la de tu comunidad en general y evita la suspensión preventiva del servicio.'
};

export const REVISION_PERIOD_TYPE = {
  Effective: 1,
  Review: 2,
  Suspension: 3
};
export const REVISION_MARK_TYPE = {
  Identification: '101',
  Certification: '103',
  NoCriticDefect: '102',
  CriticDefect: '104',
  ProcessError1: '2',
  ProcessError2: '5'
};

export const phones = [
  { label: 'Para reporte de fugas y consultas', number: '164' },
  { label: 'Línea atención nacional', number: '01 8000 915334' }
];

export const WADialogText = {
  title: '¡Chat en línea!',
  abstract: `Aquí podrás realizar consultas sobre tus servicios con ${Company.name}.`,
  buttonMessage: 'Chat en línea'
};

export const WADefaultMessage = (
  _user = null,
  _contract = null,
  _fullData = null
) => {
  return 'Hola';
};
